import LoginContainer from '../../containers/LoginContainer'

import React from 'react'

export default function MagicLinkExpired (): React.ReactElement {
  return (
    <LoginContainer>
      <div className='ResetPassword_card Login__card px-3 d-flex flex-column align-items-center justify-content-center w-100'>
        <div className='Login__title fw-bold mb-2'>Magic Link Expired</div>
        <div className="mb-4 Login__subtitle">We have sent a new magic link to your email. Please use it to login.</div>
      </div>
    </LoginContainer>
  )
}
