import { type FormikErrors, useFormik } from 'formik'
import React, { useState } from 'react'

import Modal from '../../components/Modal'
import Input from '../../components/Input/Input'
import Loader from '../../components/Loader/Loader'

import { useAppSelector, useAppDispatch } from '../../hooks/useReduxToolkit'
import { logOutUser, updateUserDetail, deleteAccount } from '../../core/store/user/userSlice'
import { useModal } from '../../hooks/useModal'
import { isEmail } from '../../core/helpers'
import mixpanel from '../../utils/mixpanel'

export interface AccountInput {
  name: string
  lastname: string
  email: string
  company: string
}

export default function AccountSetting (): React.ReactElement {
  const dispatch = useAppDispatch()
  const user = useAppSelector((s) => s.user.user)

  const [loading, setLoading] = useState(false)

  const {
    isModalOpened: isDeleteModalOpened,
    closeModal: closeDeleteModal,
    openModal: openDeleteModal
  } = useModal()

  const formik = useFormik<AccountInput>({
    initialValues: {
      name: user?.name ?? '',
      lastname: user?.lastname ?? '',
      email: user?.email ?? '',
      company: user?.company ?? ''
    },
    validate: (values) => {
      const errors: FormikErrors<AccountInput> = {}
      if (values.name.length === 0) {
        errors.name = 'Required'
      }
      if (values.company.length === 0) {
        errors.company = 'Required'
      }
      if (values.email.length === 0) {
        errors.email = 'Required'
      }
      if ((values.email.length > 0) && !isEmail(values.email)) {
        errors.email = 'Email not valid'
      }
      return errors
    },
    onSubmit: async (values) => {
      setLoading(true)
      void dispatch(updateUserDetail(values)).then(() => {
        setLoading(false)
      })
      mixpanel.track('Account Update')
    }
  })

  return (
    <>
      {loading && <Loader />}
      <div className='account-setting'>
        <form className='w-100' onSubmit={formik.handleSubmit}>
          <Input
            onBlur={formik.handleBlur}
            value={formik.values.name}
            onChange={formik.handleChange}
            name='name'
            label='First Name'
            className='mb-4 light'
            error={(formik.touched.name ?? false) && formik.errors.name}
          />
          <Input
            onBlur={formik.handleBlur}
            value={formik.values.lastname}
            onChange={formik.handleChange}
            name='lastname'
            label='Last Name'
            className='mb-4 light'
            error={(formik.touched.lastname ?? false) && formik.errors.lastname}
          />
          <Input
            onBlur={formik.handleBlur}
            value={formik.values.email}
            onChange={formik.handleChange}
            name='email'
            label='Email'
            className='mb-4 light'
            disabled={true}
            error={(formik.touched.email ?? false) && formik.errors.email}
          />
          <Input
            onBlur={formik.handleBlur}
            value={formik.values.company}
            onChange={formik.handleChange}
            name='company'
            label='Company Name'
            className='mb-4 light'
            error={(formik.touched.company ?? false) && formik.errors.company}
          />

          {/* {((formik.values.name !== user.name) || (formik.values.company !== user.company) || (formik.values.lastname !== user.lastname)) && (
            <div className='w-100 change-password'>
              <a onClick={() => { }} className="btn-password py-2">Change Password?</a>
            </div>
          )} */}
          <div className='w-100 delete-content'>
            <a onClick={() => {
              openDeleteModal()
            }} className="btn-delete py-2">Delete Account</a>
          </div>
          {/* <div className="fw-bold mb-2">Account Permissions - {((user?.isAdmin) ?? false) ? 'Administrator' : 'Member'}</div> */}
          {
            !((user?.isAdmin) ?? false) &&
            <p className='italicText '>Contact your admin to upgrade your status from member to admin</p>
          }
          <div className='mt-5 d-flex align-items-center'>
            <button disabled={(formik.values.name === user.name) && (formik.values.company === user.company) && (formik.values.lastname === user.lastname)} className='btn me-2 me-lg-4 save-button' type='submit'>
              Save Changes
            </button>
            {((formik.values.name !== user.name) || (formik.values.company !== user.company) || (formik.values.lastname !== user.lastname)) && (
              <button className='btn--outline me-2 me-lg-4 cancel-button' onClick={() => {
                formik.resetForm()
              }} type='button'>
                Cancel
              </button>
            )}
          </div>
        </form>
      </div>

      <Modal isOpened={isDeleteModalOpened} closeModal={closeDeleteModal} title="" >
        <h2 className="modal__applied__title">We’d hate to see you go!</h2>
        <div className="modal__applied__text">
          Are you sure you want to delete your account?<br />Please confirm your decision by clicking the button below.
        </div>

        <div className="modal__applied__buttons  ">
          <button onClick={() => {
            void dispatch(deleteAccount()).then(() => dispatch(logOutUser())).then(() => { window.location.reload() })
          }} type="submit" className="btn request-button ">Delete Account</button>
        </div>
      </Modal>
    </>
  )
}
