import './Dashboard.scss'

import React, { useEffect, useState, useRef } from 'react'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { produce } from 'immer'
import ReactPaginate from 'react-paginate'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

import Modal from '../../components/Modal'
import Modal2 from '../../components/Modal2'
import Loader from '../../components/Loader/Loader'
import Checkbox from '../../components/Checkbox/Checkbox'
import RatingBadge from '../../components/RatingBadge/RatingBadge'
import MultiSelectDropdown from '../../components/SelectInput/MultiSelectDropdown1'
import BadgesMultiSelectDropdown from '../../components/SelectInput/BadgesMultiSelectDropDown1'

import { useAppDispatch, useAppSelector } from '../../hooks/useReduxToolkit'
import { type RfpStatusType, type DueDateType, type FilterCategories } from '../../core/types'
import { fetchFilterItems } from '../../core/store/data/dataSlice'
import { fetchDashboardRfpList, setRfpCalledOnce, fetchStatusCounts } from '../../core/store/dashboard/dashboardSlice'
import { fetchUserDetails, updateDefaultFilters, setChanged as setuserDetailChanged } from '../../core/store/user/userSlice'
import { useIndexedDB, getDataFromDB } from '../../core/indexedDBService'

import axiosIct from '../../utils/axios'
import { useModal } from '../../hooks/useModal'

import { ReactComponent as AIcon } from '../../icons/icon-a.svg'
import { ReactComponent as FilterIcon } from '../../icons/filter.svg'
import { ReactComponent as StarIcon } from '../../icons/star.svg'
import { ReactComponent as Star1Icon } from '../../icons/star1.svg'
import { ReactComponent as Star2Icon } from '../../icons/star2.svg'
import { ReactComponent as Check2Icon } from '../../icons/check2.svg'
import { ReactComponent as Check3Icon } from '../../icons/check3.svg'
import { ReactComponent as GlobalIcon } from '../../icons/global.svg'
import { ReactComponent as EyeSlashIcon } from '../../icons/eye-slash.svg'
import { ReactComponent as LocationIcon } from '../../icons/location.svg'
import { ReactComponent as CalenderIcon } from '../../icons/calender.svg'
import { ReactComponent as Close1Icon } from '../../icons/close1.svg'
import { ReactComponent as Close2Icon } from '../../icons/close2.svg'

import mixpanel from '../../utils/mixpanel'

const RFP_STATUSES: RfpStatusType = {
  options: [{
    label: 'New',
    name: 'new',
    count: 0,
    checked: false
  },
  {
    label: 'Open',
    name: 'open',
    count: 0,
    checked: false
  },
  {
    label: 'Favorite',
    name: 'favorite',
    count: 0,
    checked: false
  },
  {
    label: 'Applied',
    name: 'apply',
    count: 0,
    checked: false
  },
  {
    label: 'Hidden',
    name: 'hide',
    count: 0,
    checked: false
  }],
  value: []
}

const DUE_DATES: DueDateType = {
  options: [{
    label: 'This Month',
    name: 'this_month',
    count: 11,
    checked: false
  },
  {
    label: 'Next Month',
    name: 'next_month',
    count: 3,
    checked: false
  },
  {
    label: 'This Quarter',
    name: 'this_quarter',
    count: 26,
    checked: false
  },
  {
    label: 'Next Quarter',
    name: 'next_quarter',
    count: 9,
    checked: false
  },
  {
    label: 'This Year',
    name: 'this_year',
    count: 30,
    checked: false
  }],
  value: []
}

// const SORT_OPTIONS = [
//   {
//     label: 'Newest',
//     value: 'newest'
//   },
//   {
//     label: 'Best Fit',
//     value: 'bestFit'
//   },
//   {
//     label: 'Due Date',
//     value: 'dueDate'
//   }
// ]

// const LIMIT_OPTIONS = [
//   {
//     label: '25',
//     value: '25'
//   },
//   {
//     label: '50',
//     value: '50'
//   },
//   {
//     label: '100',
//     value: '100'
//   }
// ]

export default function Dashboard (): React.ReactElement {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const rfpSources = useAppSelector(s => s.data.rfpSources)
  const filterItemsFetched = useAppSelector(s => s.data.fetched)
  const userDetailsChanged = useAppSelector(s => s.user.changed)
  const rfpCalledOnce = useAppSelector(s => s.dashboard.rfpCalledOnce)
  const defaultFilters = useAppSelector(s => s.user.user.defaultFilter)
  const placeOfPerformance = useAppSelector(s => s.data.placeOfPerformance)
  const { rfpList: newFetchedData, rfpCount, total, lastEvaluatedKey } = useAppSelector(s => s.dashboard)
  const [filterOption, setFilterOption] = useState(1)
  const [isScreenSmall, setIsScreenSmall] = useState(false)
  const [rfpStatuses, setRfpStatuses] = useState<RfpStatusType>(RFP_STATUSES)
  const [isFilterChange, setFilterChange] = useState(false)
  const [dueDates, setDueDates] = useState<DueDateType>(DUE_DATES)
  const [sortValue] = useState('newest')
  // const searchTimeRef = useRef<number | null>(null)
  const [loading, setLoading] = useState(false)
  const [isRfpSourceUpdate, setRfpSourceUpdate] = useState(false)
  const [searchValue, setSearchValue] = useState(sessionStorage.getItem('searchValue') ?? '')
  const [searchValueArray, setSearchValueArray] = useState<string[]>(searchValue.split(',').filter(value => value.trim() !== ''))
  const [search, setSearch] = useState('')
  const searchTimeRef = useRef<number | null>(null)
  const [limitValue] = useState('25')
  const [pageNo, setPageNo] = useState(parseInt(sessionStorage.getItem('pageNo') ?? '1'))
  const [badgeColorFilter, setBadgeColorFilter] = useState(sessionStorage.getItem('right_fit_rfp_score')?.split(',') ?? defaultFilters.right_fit_rfp_score)
  const [requestSourceName, setRequestName] = useState('')
  const [pendingPlace, setPendingPlace] = useState<any>({})
  const [enabledOptions, setEnabledOptions] = useState<any>({ pop: ['Federal'] })
  const [hideArray, setHideArray] = useState<string[]>([])

  // Usage example:
  const [currentRfpData, setCurrentRfpData] = useIndexedDB('currentRfpData', newFetchedData)

  // Calculate the index range for the current page
  const [startIndex, setStartIndex] = useState(parseInt(sessionStorage.getItem('startIndex') ?? '-1'))
  const [endIndex, setEndindex] = useState(parseInt(sessionStorage.getItem('endIndex') ?? limitValue))
  const [currentItems, setcurrentItems] = useState(currentRfpData.slice(startIndex, endIndex))

  const [rfpDataUpdate, setRfpDataUpdate] = useState(false)

  console.log(searchValueArray, searchValue)
  console.log(pageNo, startIndex, endIndex, currentRfpData.length, total, hideArray)

  const {
    isModalOpened: isUserModalOpened,
    closeModal: closeUserModal,
    openModal: openUserModal
  } = useModal()

  const [allFilters, setAllFilters] = useState([{
    subtitle: 'Place Of Performance',
    name: 'pop',
    value: sessionStorage.getItem('placeOfPerformance')?.split(',') ?? placeOfPerformance.map(nc => nc.value),
    options: placeOfPerformance
  },
  {
    subtitle: 'RFP Sources',
    name: 'source',
    value: sessionStorage.getItem('rfpSource')?.split(',') ?? defaultFilters.rfp_source,
    options: rfpSources
  }
  ])

  const {
    isModalOpened,
    closeModal,
    openModal
  } = useModal()

  const {
    isModalOpened: isFilterModalOpened,
    closeModal: closeFilterModal,
    openModal: openFilterModal
  } = useModal()

  // source request handling
  const handleRequestSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    void axiosIct.post('/user/source_request', { request: requestSourceName }).then(() => {
      setRequestName('')
      closeUserModal()
    })
    e.preventDefault()
  }

  useEffect(() => {
    if (rfpCalledOnce && !loading && total === 0 && currentRfpData.length === 0 && badgeColorFilter.length === 1 && badgeColorFilter[0] === 'A') {
      setBadgeColorFilter('A,B,C,D'.split(','))
      setFilterChange(true)
    }
  }, [loading])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smooth scrolling
    })
    setStartIndex((pageNo - 1) * parseInt(limitValue))
    sessionStorage.setItem('pageNo', pageNo.toString())
  }, [pageNo])

  useEffect(() => {
    if (pageNo === 1) {
      handlePageOnChange(1)
    }
  }, [currentRfpData])

  useEffect(() => {
    if (currentRfpData.length > 0) {
      setEndindex((startIndex + parseInt(limitValue)) > currentRfpData.length ? currentRfpData.length : (startIndex + parseInt(limitValue)))
      sessionStorage.setItem('startIndex', startIndex.toString())
    }
  }, [startIndex, currentRfpData])

  useEffect(() => {
    setcurrentItems(currentRfpData.slice(startIndex, endIndex))
    sessionStorage.setItem('endIndex', endIndex.toString())
  }, [endIndex, currentRfpData])

  useEffect(() => {
    if (lastEvaluatedKey !== null && lastEvaluatedKey !== '' && currentItems.length < parseInt(limitValue) && currentItems.length > 0) {
      setRfpDataUpdate(true)
    }
  }, [currentItems])

  useEffect(() => {
    const fetchDataAndUpdate = async (): Promise<void> => {
      try {
        const existingData = await getDataFromDB('currentRfpData')
        if (Array.isArray(existingData) && existingData?.length !== 0) {
          // Data retrieved from IndexedDB is an array of objects
          const combinedData = [...existingData, ...newFetchedData]
          // Set the combined data in IndexedDB
          setCurrentRfpData(combinedData)
        } else {
          // Data retrieved from IndexedDB is not of the expected type
          console.error('Data retrieved from IndexedDB is not of type RfpListType:', existingData)
          // Fetch initial data from API or set a default value if necessary
          setCurrentRfpData(newFetchedData)
        }
        setRfpDataUpdate(false)
      } catch (error) {
        // Handle the error, if necessary
        console.error('Error retrieving data from IndexedDB:', error)
        // Fetch initial data from API or set a default value if necessary
        setCurrentRfpData(newFetchedData)
        setRfpDataUpdate(false)
      }
    }

    const storedLastEvaluatedKey = sessionStorage.getItem('lastEvaluatedKey' ?? '')

    if (storedLastEvaluatedKey === '' || (storedLastEvaluatedKey !== lastEvaluatedKey && storedLastEvaluatedKey !== 'null') || (lastEvaluatedKey === null && currentRfpData.length === 0)) {
      void fetchDataAndUpdate()
    } else {
      sessionStorage.setItem('lastEvaluatedKey', '')
    }
    return () => {
      // Cleanup function
    }
  }, [newFetchedData])

  useEffect(() => {
    const threshold = 200
    if ((currentRfpData.length - pageNo * parseInt(limitValue) < threshold) && lastEvaluatedKey !== '' && lastEvaluatedKey !== null && !rfpDataUpdate) {
      setRfpDataUpdate(true)
    }
  }, [pageNo])

  useEffect(() => {
    if (rfpDataUpdate) {
      loadRfps(false, lastEvaluatedKey)
    }
  }, [rfpDataUpdate])

  useEffect(() => {
    setAllFilters(() => {
      return produce(allFilters, draft => {
        draft[0].options = placeOfPerformance
        draft[1].options = rfpSources
      })
    })
  }, [rfpSources, placeOfPerformance])

  useEffect(() => {
    const doAsync = async (): Promise<void> => {
      setLoading(true)
      await dispatch(fetchUserDetails())
      // setLoading(false)
      setRfpSourceUpdate(true)
    }

    if (userDetailsChanged) {
      void doAsync()
    }
    // Clean up the interval when component unmounts

    const checkScreenSize = (): void => {
      if (window.innerWidth < 576) {
        setIsScreenSmall(true)
      } else {
        setIsScreenSmall(false)
      }
    }
    checkScreenSize()
    window.addEventListener('resize', checkScreenSize)

    // Clean up event listener and interval when the component unmounts
    return () => {
      window.removeEventListener('resize', checkScreenSize)
    }
  }, [])

  useEffect(() => {
    if (!filterItemsFetched) {
      void dispatch(fetchFilterItems())
    }
  }, [filterItemsFetched])

  useEffect(() => {
    if (userDetailsChanged) {
      setAllFilters(produce(allFilters, produce => {
        produce[0].value = placeOfPerformance.map(nc => nc.value)
        produce[1].value = defaultFilters.rfp_source
      }))
      setPageNo(1)
      setFilterChange(true)
      dispatch(setuserDetailChanged(false))
    }
  }, [defaultFilters, userDetailsChanged])

  useEffect(() => {
    if (isFilterChange) {
      if (rfpCalledOnce) {
        setLoading(true)
        setCurrentRfpData([])
        loadRfps(true, '')
        sessionStorage.clear()
      }
      setFilterChange(false)
      dispatch(setRfpCalledOnce(true))
    }
  }, [isFilterChange])

  useEffect(() => {
    if (isRfpSourceUpdate) {
      setAllFilters(produce(allFilters, produce => {
        produce[1].options = rfpSources
      }))
    }
    setRfpSourceUpdate(false)
  }, [isRfpSourceUpdate])

  const onFilterChange = (index: number, values: string[]): void => {
    // Add special condition for handling pop
    if (index === 0 && values.length === 0) {
      values = ['Federal']
      toast.error('At least 1 place of performance should be selected.', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      })
    }
    setAllFilters(produce(allFilters, draft => {
      draft[index].value = values
      // if (index === 0) {
      //   const list = userplaceOfPerformance.filter(el => values.includes(el.value)).map((el: any) => el.related)
      //   // draft[3].value = ([...new Set([...draft[3].value, ...list])])
      // }
    }))
    // setPageNo(1)
    // setFilterChange(true)
  }

  //  Define a function to update actions based on item ID and index range
  const updateActions = (
    itemID: string,
    newActions: { apply: boolean, favorite: boolean, hide: boolean }
  ): void => {
    const newData = currentRfpData.slice() // Create a shallow copy of the array
    for (let i = startIndex; i <= endIndex; i++) {
      const item = newData[i]
      if (item !== null && item.id === itemID) {
        const newItem = cloneDeep(item) // Create a deep copy of the item
        newItem.actions = { ...newItem.actions, ...newActions }
        newData[i] = newItem // Update the item in place
        setCurrentRfpData(newData)
        break
      }
    }
  }

  const removeItemById = (id: string): void => {
    const index = currentRfpData.findIndex(item => item.id === id)
    console.log(index)
    if (index !== -1) {
      const updatedData = [
        ...currentRfpData.slice(0, index),
        ...currentRfpData.slice(index + 1)
      ]
      setCurrentRfpData(updatedData)
      setcurrentItems(currentRfpData.slice(startIndex, endIndex))
    }
  }

  const handleToggleIcon = async (event: React.MouseEvent | null, toggleIcon: 'apply' | 'hide' | 'favorite' | 'open', itemId: string, val: boolean): Promise<void> => {
    event?.stopPropagation()
    const requestBody = [
      {
        action: toggleIcon,
        value: !val
      }
    ]
    if (toggleIcon === 'apply') {
      setHideArray(hideArray.filter(item => item !== itemId))
      if (!val) {
        updateActions(itemId, { apply: true, hide: false, favorite: false })
        requestBody.push({ action: 'hide', value: false }, { action: 'favorite', value: false })
      } else {
        updateActions(itemId, { apply: false, hide: false, favorite: false })
        requestBody.push({ action: 'hide', value: false }, { action: 'favorite', value: false }, { action: 'apply', value: false })
      }
    } else if (toggleIcon === 'hide') {
      if (!val) {
        setHideArray([...hideArray, itemId])
        updateActions(itemId, { apply: false, hide: true, favorite: false })
        requestBody.push({ action: 'favorite', value: false }, { action: 'apply', value: false })
      } else {
        if (rfpStatuses.value.includes('hide')) {
          removeItemById(itemId)
        } else {
          setHideArray(hideArray.filter(item => item !== itemId))
          updateActions(itemId, { apply: false, hide: false, favorite: false })
        }
        requestBody.push({ action: 'favorite', value: false }, { action: 'apply', value: false }, { action: 'hide', value: false })
      }
    } else if (toggleIcon === 'favorite') {
      setHideArray(hideArray.filter(item => item !== itemId))
      if (!val) {
        updateActions(itemId, { apply: false, hide: false, favorite: true })
        requestBody.push({ action: 'hide', value: false }, { action: 'apply', value: false })
      } else {
        updateActions(itemId, { apply: false, hide: false, favorite: false })
        requestBody.push({ action: 'hide', value: false }, { action: 'apply', value: false }, { action: 'favorite', value: false })
      }
    } else {
      setHideArray(hideArray.filter(item => item !== itemId))
      // dispatch(changeIcon({ val: !val, itemId, toggleIcon }))
    }
    await axiosIct.post(`/data/rfp/${itemId}/action`, requestBody).then(r => {
      mixpanel.track('Action ' + (toggleIcon.charAt(0).toUpperCase() + toggleIcon.slice(1)))
    })

    void dispatch(fetchStatusCounts(rfpCount))
    // if (toggleIcon === 'hide') {
    //   const newList = dashboardFetchData.filter(rfp => rfp.id !== itemId)
    //   dispatch(setRfpList([...newList]))
    // }
  }

  // handling filter according to categories
  const handleFilters = (event: React.ChangeEvent, FilterCategory: FilterCategories, payload: { name: string }): void => {
    if (FilterCategory === 'rfpStatus') {
      setRfpStatuses(produce<RfpStatusType>(rfpStatuses, (draft) => {
        const index = draft.options.findIndex(item => item.name === payload.name)
        draft.options[index].checked = !draft.options[index].checked
        draft.value = draft.options.filter((i) => {
          return i.checked
        }).map(i => i.name)
      })
      )
    }
    if (FilterCategory === 'dueDate') {
      setDueDates(produce<DueDateType>(dueDates, (draft) => {
        const index = draft.options.findIndex((item) => item.name === payload.name)
        draft.options[index].checked = !draft.options[index].checked
        draft.value = draft.options.filter((i) => {
          return i.checked
        }).map(i => i.name)
      }))
    }
    // setPageNo(1)
    // setFilterChange(true)
    event.stopPropagation()
  }

  const openRfpDetail = (id: string, index: number, rfpRightFitScore: string): void => {
    sessionStorage.setItem('lastEvaluatedKey', lastEvaluatedKey)

    sessionStorage.setItem('placeOfPerformance', allFilters[0].value.join(','))
    sessionStorage.setItem('rfpSource', allFilters[1].value.join(','))
    sessionStorage.setItem('searchValue', searchValue)

    sessionStorage.setItem('right_fit_rfp_score', badgeColorFilter.join(','))

    if ((currentItems[index].page_text.length === 0) && (currentItems[index].rfp_url.length > 0)) {
      void handleToggleIcon(null, 'open', id, false)
      window.open(currentItems[index].rfp_url, '_blank')
    } else if (currentItems[index].page_text.length > 0) {
      const rfpId: string = currentItems[index].id
      // loadRfps(false, '')
      navigate(`/rfp/${rfpId}/${rfpRightFitScore}`)
    } else if (currentItems[index].is_rfp_file.length > 0) {
      void axiosIct.get(`${process.env.REACT_APP_API_URL ?? ''}/data/rfp/${currentItems[index].id}/file`).then(res => {
        window.open(res.data.url, '_blank')
      })
    }
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (searchTimeRef.current != null) {
      setSearch(e.target?.value ?? '')
      clearTimeout(searchTimeRef.current)
    }
    searchTimeRef.current = window.setTimeout(() => {
      setSearchValueArray([...searchValueArray, e.target.value])
      setSearch('')
    }, 3000)
  }

  let loadRfpTimeout: any = null

  const loadRfps = (loadingStatus: boolean, lastKey: string): void => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (loadRfpTimeout) {
      clearTimeout(loadRfpTimeout)
    }
    loadRfpTimeout = setTimeout(() => {
      setLoading(loadingStatus)
      // if (dashboardFetchData.length === 0) {
      //   setLoading(true)
      // }
      const search = searchValue
      const sort = sortValue
      const date = (dueDates.value ?? []).join(',')
      const status = (rfpStatuses.value ?? []).join(',')
      const rightFitScore = badgeColorFilter.join(',')
      const pop = (allFilters[0].value ?? []).join(',')
      const source = (allFilters[1].value ?? []).join(',')
      const limit = +limitValue
      const page = +pageNo

      void dispatch(fetchDashboardRfpList({ search, sort, date, status, right_fit_score: rightFitScore, source, pop, limit, page, lastEvaluatedKey: lastKey })).then(() => {
        setLoading(false)
      })
    }, 1000)
  }

  const handlePageOnChange = (currentpage: any): void => {
    setPageNo(currentpage)
  }

  const activeFiltersCount = (): number => {
    let num = 0
    num += ((dueDates.value ?? []).length > 0) ? 1 : 0
    num += ((rfpStatuses.value ?? []).length > 0) ? 1 : 0
    num += (badgeColorFilter.length > 0) ? 1 : 0
    // Count for generic filters
    num += allFilters.reduce((count, filter) => {
      const validValues = (filter.value ?? []).filter(val => val.trim() !== '') // Ensure no empty values
      return count + (validValues.length > 0 ? 1 : 0)
    }, 0)
    return num
  }

  const clearRFP = (event: React.MouseEvent): void => {
    setRfpStatuses(produce(rfpStatuses, draft => {
      // eslint-disable-next-line array-callback-return
      draft.options.map(i => {
        i.checked = false
      })
      draft.value = []
    }))
    setPageNo(1)
    setFilterChange(true)
    event.stopPropagation()
  }

  const selectRFPAllOptions = (event: React.MouseEvent): void => {
    setRfpStatuses(produce(rfpStatuses, draft => {
      // eslint-disable-next-line array-callback-return
      draft.options.map(i => {
        i.checked = true
      })
      draft.value = draft.options.filter((i) => {
        return i.checked
      }).map(i => i.name)
    }))
    setPageNo(1)
    setFilterChange(true)
    event.stopPropagation()
  }

  const clearDue = (event: React.MouseEvent): void => {
    setDueDates(produce(dueDates, draft => {
      // eslint-disable-next-line array-callback-return
      draft.options.map(i => {
        i.checked = false
      })
      draft.value = []
    }))
    setPageNo(1)
    setFilterChange(true)
    event.stopPropagation()
  }

  const selectDueAllOptions = (event: React.MouseEvent): void => {
    setDueDates(produce(dueDates, draft => {
      // eslint-disable-next-line array-callback-return
      draft.options.map(i => {
        i.checked = true
      })
      draft.value = draft.options.filter((i) => {
        return i.checked
      }).map(i => i.name)
    }))
    setPageNo(1)
    setFilterChange(true)
    event.stopPropagation()
  }

  // clear all filter
  const clearFilters = (event: React.MouseEvent): void => {
    // set it to default filters instead of clearing out all

    setAllFilters(produce(allFilters, produce => {
      produce[0].value = placeOfPerformance.map(nc => nc.value)
      produce[1].value = defaultFilters.rfp_source
    }))

    // setAllFilters(produce(allFilters, draft => {
    //   // eslint-disable-next-line array-callback-return
    //   draft.map(i => {
    //     i.value = []
    //   })

    // }))
    // setBadgeColorFilter([])
    // setDueDates(produce(dueDates, draft => {
    //   // eslint-disable-next-line array-callback-return
    //   draft.options.map(i => {
    //     i.checked = false
    //   })
    //   draft.value = []
    // }))
    // setRfpStatuses(produce(rfpStatuses, draft => {
    //   // eslint-disable-next-line array-callback-return
    //   draft.options.map(i => {
    //     i.checked = false
    //   })
    //   draft.value = []
    // }))
    setPageNo(1)
    setFilterChange(true)
    closeFilterModal()
    event.stopPropagation()
  }

  const onDisabledOptionClick = (index: number, option: any): void => {
    if (index === 0) {
      setPendingPlace(option)
      openModal()
    }
  }

  const addDefaultPlace = (value: string): void => {
    if (defaultFilters !== null) {
      const data: any = { ...defaultFilters }
      data.place_of_performance = [value, ...data.place_of_performance]
      const pop = [...(enabledOptions.pop ?? []), value]
      setEnabledOptions({ pop })
      void dispatch(updateDefaultFilters(data)).then(() => {
        void dispatch(fetchUserDetails())
      })
    }
    closeModal()
  }

  const onSearchValueSubmit = (e: any): void => {
    if (e.key === 'Enter') {
      if (e.target.value.length < 4) {
        toast.error('Please enter atleast 4 letters for search.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark'
        })
      } else {
        setSearchValueArray([...searchValueArray, e.target.value])
        setSearch('')
        if (searchTimeRef.current != null) {
          clearTimeout(searchTimeRef.current)
        }
      }
    } else {
      setSearch(e.target?.value ?? '')
    }
  }

  const onSearchClearAll = (e: any): void => {
    setSearchValueArray([])
    setSearchValue('')
    setPageNo(1)
    setFilterChange(true)
    e.stopPropagation()
  }

  useEffect(() => {
    if (searchValueArray.join(',') !== searchValue) {
      if (searchValueArray.length === 0) {
        setSearchValue('')
      } else {
        setSearchValue(searchValueArray.join(','))
      }
      setPageNo(1)
      setFilterChange(true)
    }
  }, [searchValueArray])

  return (
    <>
      <div className="container dashboard py-5">
        <div className="dashboard__header">
          {/* <h2 className="dashboard__header__title">RFP Leads</h2> */}
          <div className="dashboard__header__tools">
            {/* <div className="dashboard-search"> */}
              <div className='collapse-menu'>
                  <div className="dropdown-search">
                    <input type="text" value={search} placeholder='Search terms' onChange={handleSearch} onKeyDown={onSearchValueSubmit} />
                    {/* <SearchIcon /> */}
                    {/* <button className='btn' onClick={handleSearch}> Search </button> */}
                  </div>
                <div className='d-flex'>
                    <a onClick={onSearchClearAll} className="clearSelect">Remove All</a>
                </div>
                  <div className='d-flex naics-wrapper'>
                    {searchValueArray?.map((item, index) => (
                      <div className='naics-item' key={index} onClick={() => {
                        setSearchValueArray(searchValueArray?.filter(ite => ite !== item))
                      }}>{item} <Close2Icon /></div>
                    ))}
                  </div>
              </div>
            {/* </div> */}
            <div className='dashboard_filter_btn' onClick={openFilterModal}>
              <FilterIcon />
              <div style={{ width: 10 }} />
              {(activeFiltersCount() === 1) ? 'Filter' : 'Filters'}{(activeFiltersCount() !== 0) ? `(${activeFiltersCount()})` : ''}
            </div>
            {/* <div className='dashboard-sorting d-flex'>
              <SelectDropdown className="ms-lg-auto me-2 w-135 w-lg-150" options={LIMIT_OPTIONS} value={limitValue} onChange={setLimitValue} MarkChange={() => { setPageNo(1); setFilterChange(true) }} placeLabel="Limit By" />
              <SelectDropdown options={SORT_OPTIONS} value={sortValue} onChange={setSortValue} MarkChange={() => { setPageNo(1); setFilterChange(true) }} placeLabel="Sort By" />
            </div> */}
          </div>
        </div>
        <div className='filter-header'>
          {searchValue !== '' && (
            <div>
              <div className='dashboard_title_one'>
                {'Search for '}<i>{'"'}{searchValue}{'"'}</i>
              </div>
              {activeFiltersCount() !== 0 && (
                <div className='dashboard_title_two'>
                  {activeFiltersCount()} {(activeFiltersCount() === 1) ? 'filter applied' : 'filters applied'}
                </div>
              )}
              {activeFiltersCount() === 0 && (
                <div className='dashboard_title_two' />
              )}
            </div>
          )}
          {(searchValue === '' && (activeFiltersCount() === 0)) && (
            <div>
              <div className='dashboard_title_one'>
                RFPs Recommended for you
              </div>
              <div className='dashboard_title_two'>
                Based on your profile and searches
              </div>
            </div>
          )}
          {(searchValue === '' && (activeFiltersCount() !== 0)) && (
            <div>
              <div className='dashboard_title_one'>
                Filtered Search
              </div>
              <div className='dashboard_title_two'>
                {activeFiltersCount()} {(activeFiltersCount() === 1) ? 'filter applied' : 'filters applied'}
              </div>
            </div>
          )}
          {activeFiltersCount() !== 0 && (
            <div className='dashboard_title_two filter_clear' onClick={clearFilters}>
              Clear filters
            </div>
          )}
        </div>

        <div className="d-lg-flex">
          <div className="dashboard__content">
            {loading && <Loader></Loader>}
            {!loading && currentItems.map((item, index) => {
              // if (index >= ((pageNo - 1) * parseInt(limitValue)) && index <= ((pageNo) * parseInt(limitValue))) {
              return (
                <div className={'post-box ' + (((item.page_text.length > 0) ?? (item.rfp_url.length > 0) ?? (item.is_rfp_file.length > 0)) ? 'active' : '')} key={item.id} onClick={() => { openRfpDetail(item.id, index, item.right_fit_score) }} >
                  <div className="post-box__header">
                    <div className='post-box-wrapper'><div className="post-box__score"><RatingBadge letter={item.right_fit_score} /> Right-Fit Score</div>
                      {item.actions.apply && (<div className="post-box__applied">
                        APPLIED<Check3Icon />
                      </div>)}
                      {item.actions.favorite && (<div className="post-box__favorite">
                        SAVED<Star2Icon />
                      </div>)}
                      {item.actions.hide && !hideArray.includes(item.id) && (<div className="post-box__hide">
                        HIDDEN<EyeSlashIcon />
                      </div>)}
                    </div>
                    <div>
                      <EyeSlashIcon
                        onClick={(event: React.MouseEvent) => {
                          void handleToggleIcon(event, 'hide', item.id, item.actions.hide)
                        }}
                        className={item.actions.hide ? 'active_hide' : ''} />
                      <Check2Icon
                        onClick={(event: React.MouseEvent) => {
                          void handleToggleIcon(event, 'apply', item.id, item.actions.apply)
                        }}
                        className={item.actions.apply ? 'active' : ''} />
                      <StarIcon
                        onClick={(event: React.MouseEvent) => {
                          void handleToggleIcon(event, 'favorite', item.id, item.actions.favorite)
                        }}
                        className={item.actions.favorite ? 'active_favorite' : ''} />
                    </div>
                  </div>

                  <h3 className="post-box__title">{item.rfp_title}</h3>
                  <div className='post-box_detail'>
                    <h6 className="post-box__subtitle"><LocationIcon /> Place Of Performance: <span className="font-weight-normal">{item.friendly_name}</span></h6>
                    <h6 className="post-box__subtitle"><GlobalIcon /> RFP Source: <span className="font-weight-normal">  {(item.rfp_source.length > 0) ? item.rfp_source : 'Check RFP Details'}</span></h6>
                  </div>
                  <div className='post-box_detail'>
                    <h6 className="post-box__subtitle"><CalenderIcon /> Due: <span className="font-weight-normal">{(item.due_date != null) ? moment(new Date(item.due_date)).format('MM/DD/YYYY') : 'Check RFP Details'}</span></h6>
                    <h6 className="post-box__subtitle"><CalenderIcon /> Posted: <span className="font-weight-normal">{(item.posted_date != null) ? moment(new Date(item.posted_date)).format('MM/DD/YYYY') : 'Check RFP Details'}</span></h6>
                  </div>
                  {(item.actions.hide && hideArray.includes(item.id) &&
                    <div className='post-box__hidden-modal'>
                      <div>
                        <p>Hidden. We won&apos;t show you this opportunity anymore.</p>
                        <button onClick={(event: React.MouseEvent) => {
                          void handleToggleIcon(event, 'hide', item.id, item.actions.hide)
                        }}>Undo</button>
                        <Close1Icon className='post-box__hidden-svg' onClick={(event: React.MouseEvent) => {
                          removeItemById(item.id)
                          event?.stopPropagation()
                        }} />
                      </div>
                    </div>
                  )}
                </div>
              )
              // } else {
              //   return <div key={item.id}></div>
              // }
            })}
            {!loading &&
              currentRfpData?.length === 0 && (
                <>
                <div className='dashboard_no_result'>
                    No Results
                </div>
                </>
            )
            }
            {
              !loading &&
              <ReactPaginate
                previousLabel="<"
                nextLabel=">"
                breakLabel="..."
                className="react-pagination p-0"
                pageCount={Math.ceil(currentRfpData.length / parseInt(limitValue)) }
                marginPagesDisplayed={isScreenSmall ? 0 : 0}
                pageRangeDisplayed={Math.ceil(currentRfpData.length / parseInt(limitValue)) > 5 ? 5 : Math.floor(currentRfpData.length / parseInt(limitValue))}
                onPageChange={(e) => { handlePageOnChange(e.selected + 1) }}
                forcePage={pageNo - 1}
                renderOnZeroPageCount={null}
                nextClassName='next'
                previousClassName='next'
                disabledClassName='disable'
              />
            }

          </div>
        </div>
      </div>

      <Modal isOpened={isUserModalOpened} closeModal={closeUserModal} title="" >
        <h2 className="modal__applied__title">Request RFP Source</h2>
        <div className="modal__applied__text">
          Let us know what RFP Sources you track, and we’ll add them into One Click!
        </div>
        <form onSubmit={handleRequestSubmit} style={{ width: '90%', margin: 'auto' }} >
          <p className="fw-bold mb-2">Source URL</p>
          <input value={requestSourceName} onChange={(e) => { setRequestName(e.target.value) }} className='w-100 p-3 sourceInput '

          />
          <div className="modal__applied__buttons  ">
            <button type="submit" className="btn request-button ">Send Request</button>
          </div>
        </form>
      </Modal>

      <Modal isOpened={isModalOpened} closeModal={closeModal} title="" >
        <h2 className="modal__applied__title">Add {pendingPlace.label}</h2>
        <div className="modal__applied__text">
          Adding {pendingPlace.label} will adjust your subscription <br /> and your billing will be reflected. <br /> <br /> Are you sure?
        </div>
        <div className="button-container text-center" >
          <button className='btn' type='button' onClick={(e) => { addDefaultPlace(pendingPlace.value) }} >
            Yes! Let's get started!
          </button>
          <button type='button' className='btn--outline fw-bold ms-4' onClick={(e) => { closeModal() }}>
            No, take me back
          </button>
        </div>
      </Modal>

      <Modal2 isOpened={isFilterModalOpened} closeModal={closeFilterModal} title="">
        <div className="filter-wrapper">
          <div id="filter-title">
            <h4 className="title">Filters {(activeFiltersCount() !== 0) ? `(${activeFiltersCount()})` : ''}</h4>
          </div>
          <div className='filter-main row'>
            <div className='col-5 filter-left'>
              <div className={filterOption === 1 ? 'filter-option active' : 'filter-option'} onClick={() => { setFilterOption(1) }}>
                <div className='filter-icon'><Star1Icon /></div>RFP Status
              </div>
              <div className={filterOption === 2 ? 'filter-option active' : 'filter-option'} onClick={() => { setFilterOption(2) }}>
                <div className='filter-icon'><CalenderIcon /></div> Due Date
              </div>
              <div className={filterOption === 3 ? 'filter-option active' : 'filter-option'} onClick={() => { setFilterOption(3) }}>
                <div className='filter-icon'><AIcon /></div> Right-Fit RFP Score
              </div>
              <div className={filterOption === 4 ? 'filter-option active' : 'filter-option'} onClick={() => { setFilterOption(4) }}>
                <div className='filter-icon'><LocationIcon /></div> Place of Performance
              </div>
              <div className={filterOption === 5 ? 'filter-option active' : 'filter-option'} onClick={() => { setFilterOption(5) }}>
                <div className='filter-icon'><GlobalIcon /></div> RFP Source
              </div>
            </div>
            <div className='col-7 filter-right'>
              {filterOption === 1 && (
                <div className="dashboard__filter__box">
                  <a className='clearSelect' onClick={selectRFPAllOptions} >Select All</a>
                  <a className='clearSelect' onClick={clearRFP} >Deselect All</a>
                  <ul className="filter-list-container">
                    {
                      rfpStatuses.options.map((status, index) =>
                        <div key={status.label + String(index)} className="filter-item-container">
                          <li className='filter-item' key={status.name}><Checkbox fade={false} checked={status.checked} label={status.label} onChange={(e) => { handleFilters(e, 'rfpStatus', { name: status.name }) }} labelColor=" #415473" /></li>
                          <span className="count" >{rfpCount?.status[status.name] ?? 0}</span>
                        </div>
                      )
                    }
                  </ul>
                </div>
              )}
              {filterOption === 2 && (
                <div className="dashboard__filter__box">
                  <a className='clearSelect' onClick={selectDueAllOptions} >Select All</a>
                  <a className='clearSelect' onClick={clearDue} >Deselect All</a>
                  <ul className="filter-list-container">
                    {
                      dueDates.options.map((status, index) =>
                        <div key={status.name + String(index)} className="filter-item-container">
                          <li className='filter-item'><Checkbox fade={false} checked={status.checked} label={status.label} onChange={(e) => { handleFilters(e, 'dueDate', { name: status.name }) }} labelColor=" #415473" /></li>
                          <span className="count" >{rfpCount?.due_date[status.name] ?? 0}</span>
                        </div>
                      )
                    }
                  </ul>
                </div>
              )}
              {filterOption === 3 && (
                <div className="dashboard__filter__box" >
                  <BadgesMultiSelectDropdown value={badgeColorFilter} counts={rfpCount?.right_fit_score} setValueChange={(val) => { sessionStorage.setItem('right_fit_rfp_score', val.join(',')); setBadgeColorFilter(val) }} />
                </div>
              )}
              {filterOption === 4 && (
                <React.Fragment key={allFilters[0].subtitle + '0'} >
                  {<div className="dashboard__filter__box">
                    <MultiSelectDropdown selectAll={true} name={allFilters[0].name} value={allFilters[0].value} title={allFilters[0].subtitle} counts={rfpCount[allFilters[0].name]} options={allFilters[0].options.map((o: any) => ({ disabled: (o.isDefault === false) && (enabledOptions.pop ?? []).includes(o.value) === false, ...o }))} onChange={(values) => { sessionStorage.setItem('placeOfPerformance', values.join(',')); onFilterChange(0, values) }} onDisabledClick={(o) => { onDisabledOptionClick(0, o) }} />
                  </div>}
                </React.Fragment>
              )}
              {filterOption === 5 && (
                <React.Fragment key={allFilters[1].subtitle + '1'} >
                  {<div className="dashboard__filter__box">
                    <MultiSelectDropdown selectAll={true} name={allFilters[1].name} value={allFilters[1].value} title={allFilters[1].subtitle} counts={rfpCount[allFilters[1].name]} options={allFilters[1].options.map((o: any) => ({ disabled: (o.isDefault === false) && (enabledOptions.pop ?? []).includes(o.value) === false, ...o }))} onChange={(values) => { sessionStorage.setItem('rfpSource', values.join(',')); onFilterChange(1, values) }} onDisabledClick={(o) => { onDisabledOptionClick(1, o) }} />
                    <div className="rfpRequestContainer" >
                      <div className='request-source-text' >Want a source that we don’t have?
                        Request a new source and we{"'"}ll get on it for you</div>
                      <button className="btn btn-lg request-source-button" onClick={() => { openUserModal() }} >Request Source</button>
                    </div>
                  </div>}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
        <div className='mt-4 mb-4 border-bottom' />
        <div className='filter-buttons'>
          <button onClick={() => { setPageNo(1); setFilterChange(true); closeFilterModal() }} className="btn btn-lg edit-button">
            <span>Apply Filters</span>
          </button>
          <div className='clear-button' onClick={clearFilters}>Clear All Filters</div>
        </div>
        {/* <div className="dashboard__filter">
          <div className="button-container">
            <button onClick={(e) => {
              dispatch(setSettingTab(2))
              navigate('/settings')
            }} className="btn btn-lg edit-button">
              <SettingIcon /> <span>Edit default settings</span>
            </button>
          </div>
        </div> */}
      </Modal2>
    </>
  )
}
