import './Login.scss'
import React, { useState } from 'react'
import { type FormikErrors, useFormik } from 'formik'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
// import LoginContainer from '../../containers/LoginContainer1'

import Modal from '../../components/Modal'
import Loader from '../../components/Loader/Loader'
import Input from '../../components/Input/Input'
import SvgList from '../../components/Animation/Animation'
import { ReactComponent as Logo1Icon } from '../../icons/logo1.svg'

import { isEmail } from '../../core/helpers'
import mixpanel from '../../utils/mixpanel'
import axiosIct from '../../utils/axios'
import { useModal } from '../../hooks/useModal'

interface FormikValues {
  email: string
}

export default function LoginMagicLink (): React.ReactElement {
  const [isLoading, setIsLoading] = useState(false)

  const {
    isModalOpened,
    closeModal,
    openModal
  } = useModal()

  const onSignUp = (email: string): void => {
    // Add special condition for handling pop
    setIsLoading(true)
    localStorage.clear()
    sessionStorage.clear()
    closeModal()
    axiosIct.post('/user/stripe-register', { email: email }).then((res) => {
    }).then(() => {
      toast.success('Magic Link has been Successfully to your email.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      })
    }).catch(e => {
      toast.error(e.response.data.error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      })
    }).finally(() => {
      setIsLoading(false)
    })
    mixpanel.track('SignUpMagicLink')
  }

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validate: (values) => {
      const errors: FormikErrors<FormikValues> = {}
      if (values.email.length === 0) {
        errors.email = 'Required'
      }
      if ((values.email !== '') && !isEmail(values.email)) {
        errors.email = 'Email not valid'
      }
      return errors
    },
    onSubmit: async (values) => {
      setIsLoading(true)
      localStorage.clear()
      sessionStorage.clear()
      axiosIct.post('/user/request-magic-link', { email: values.email }).then((res) => {
      }).then(() => {
        toast.success('Magic Link has been Successfully to your email.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark'
        })
      }).catch(e => {
        if (e.response.data.error === 'User Not Found') {
          openModal()
        } else {
          toast.error(e.response.data.error, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark'
          })
        }
      }).finally(() => {
        setIsLoading(false)
      })
      mixpanel.track('LoginMagicLink')
    }
  })

  return (
    <>
    <div className="Header">
      <div className="Header__left h-100">
        <Link onClick={() => { }} to="/">
          <Logo1Icon style={{ height: 50 }} />
        </Link>
      </div>
    </div>
    <div className='login-anim-container'>

    {isLoading && <Loader />}
    <div className='anim-left d-flex flex-column align-items-center justify-content-center'>
    <SvgList />
    </div>
    {/* <LoginContainer> */}
    <div className="Login__card px-3 d-flex flex-column align-items-center justify-content-center w-100 h-100 anim-right">
            <div className="Login__title fw-bold mb-2">Log In</div>
            {/* <div className="mb-4 Login__subtitle">Welcome! Let’s Get Started.</div> */}
            <form className="w-100 h-100 Login__form align-items-center justify-content-center text-align-center" onSubmit={formik.handleSubmit}>
              <Input
                onBlur={formik.handleBlur}
                value={formik.values.email}
                onChange={formik.handleChange}
                name="email"
                label="Email"
                className="mb-3"
                error={(formik.touched.email === true) && formik.errors.email}
              />
              <div className="d-flex flex-column align-items-center justify-content-center  pb-2 button-container">
                <button className="btn w-100 Login__btn1 " type="submit">
                  Email Magic link
                </button>
              </div>
            </form>
            <Modal isOpened={isModalOpened} closeModal={closeModal} title="" >
                <h2 className="modal__applied__title">SignUp</h2>
                <div className="modal__applied__text">
                  UserName Not Found<br /> Would you like to Sign Up Instead ?<br />
                </div>
                <div className="button-container text-center" >
                  <button className='btn' type='button' onClick={(e) => { onSignUp(formik.values.email) }} >
                    Sign Up
                  </button>
                  <button type='button' className='btn--outline fw-bold ms-4' onClick={(e) => { closeModal() }}>
                    Cancel
                  </button>
                </div>
            </Modal>
    </div>
    {/* </LoginContainer> */}

    </div>

    </>
  )
}
