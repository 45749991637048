import React, { useState, useEffect } from 'react'
import './Animation.scss' // Import the CSS file with transition classes
import { ReactComponent as RfpFilterIcon } from '../../icons/rfpfilter.svg'
import { ReactComponent as RfpPrefIcon } from '../../icons/rfppref.svg'
import { ReactComponent as RfpSourceIcon } from '../../icons/rfpsource.svg'
import { ReactComponent as RfpRightFit } from '../../icons/rightfit.svg'

const SvgList: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const svgs: any = [RfpPrefIcon, RfpSourceIcon, RfpRightFit, RfpFilterIcon]
  const texts: any = [
    '<div ><h2 class="fw-bold mb-4">We start with you<span class="orange-dot">.</span></h2></div>',
    '<div ><h2 class="fw-bold mb-4">We assess thousands of options<span class="orange-dot">.</span></h2></div>',
    '<div ><h2 class="fw-bold mb-4">We only show you what you care about<span class="orange-dot">.</span></h2></div>',
    '<div ><h2 class="fw-bold mb-4">You take it from here<span class="orange-dot">.</span></h2></div>'
  ]

  const interval: number = 3000 // Interval in milliseconds

  useEffect(() => {
    const intervalId: NodeJS.Timeout = startInterval(interval, activeIndex, svgs.length)
    return () => { clearInterval(intervalId) }
  }, [activeIndex, svgs.length, interval])

  const startInterval = (interval: number, activeIndex: number, length: number): NodeJS.Timeout => {
    return setInterval(() => {
      setActiveIndex((activeIndex + 1) % length)
    }, interval)
  }

  return (
    <>
    <div className='align-items-center text-align-center justify-content-between'>
    <div className="svg-container">
        {svgs.map((Svg: any, index: number) => (
          <div
            key={index}
            className={index === activeIndex ? 'active' : 'inactive'}
          >
            {index === activeIndex &&
            <>
            <Svg viewbox="0 0 520 330" className="svg-icon"/>
            </>
            }
          </div>
        ))}
    </div>
    <div className="AnimText" dangerouslySetInnerHTML={{ __html: texts[activeIndex] }}/>
    </div>
    </>
  )
}

export default SvgList
