import React from 'react'
import { ReactComponent as Pdf } from '../../icons/pdf.svg'
import { ReactComponent as Ppt } from '../../icons/ppt.svg'
import { ReactComponent as Doc } from '../../icons/doc.svg'
import { ReactComponent as Xls } from '../../icons/xls.svg'

const icons: any = {
  pdf: <Pdf />,
  ppt: <Ppt />,
  doc: <Doc />,
  xls: <Xls />
}

export default function FileIcon ({ filename }: { filename: string }): React.ReactElement {
  const extension: string = filename.split('.').pop() ?? 'pdf'
  let icon = icons[extension]
  if (icon === undefined) {
    for (const key in icons) {
      if (Object.prototype.hasOwnProperty.call(icons, key)) {
        if (filename.toLowerCase().includes(key)) {
          icon = icons[key]
          break
        }
      }
    }
  }
  return icon
}
