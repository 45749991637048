import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './core/store/index'
import ReactGA from 'react-ga4'
import './index.css'

if (typeof process.env.REACT_APP_GOOGLE_ANALYTICS === 'string' && process.env.REACT_APP_GOOGLE_ANALYTICS.length !== 0) {
  console.log('process.env.REACT_APP_GOOGLE_ANALYTICS', typeof process.env.REACT_APP_GOOGLE_ANALYTICS)
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS ?? '')
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <Provider store={store}>
    <App />
  </Provider>
)

reportWebVitals()
