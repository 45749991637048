import './BadgesMultiSelectDropDown.scss'
import React, { useState } from 'react'
import { produce } from 'immer'

import Checkbox from '../Checkbox/Checkbox'
import { BADGE_COLORS } from '../../core/data'

import { ReactComponent as ArrowDownIcon } from '../../icons/arrows-down-solid.svg'
import { ReactComponent as ArrowUpIcon } from '../../icons/arrows-up.svg'

interface Props {
  value: string[]
  markChange?: (a: boolean) => void
  setValueChange: (a: any) => void
  counts?: any
}

export default function BadgesMultiSelectDropdown ({ value, counts = {}, markChange, setValueChange }: Props): React.ReactElement {
  const [showMenu, setShowMenu] = useState(true)

  const clearAll = (): void => {
    setValueChange([])
  }
  const selectAllOptions = (): void => {
    setValueChange([...BADGE_COLORS.map((badge) => badge.name)])
  }

  return (
    <div className={'MultiSelectDropdown ' + (showMenu ? 'active' : '')}>

      {!showMenu && (<div className="MultiSelectDropdown__input" onClick={() => { setShowMenu(!showMenu) }}>
        <p className="filter-title">{'Right-Fit RFP Score'}</p>
        {!showMenu ? <ArrowDownIcon /> : <ArrowUpIcon />}
      </div>)}
      <div className=' collapse-menu bottom-border-none'>
        <a className='clearSelect' onClick={() => { selectAllOptions() }} >Select All</a>
        <a className='clearSelect' onClick={() => { clearAll() }} >Deselect All</a>
        <div className="row">
          {
            BADGE_COLORS.map((badge, index) =>
              <div key={badge.name} className="mt-3 d-flex">
                <Checkbox checked={value.includes(badge.name)} label={badge.name}
                  onChange={() => {
                    const newValue = produce(value, draft => {
                      if (draft.includes(badge.name)) {
                        draft.splice(draft.indexOf(badge.name), 1)
                      } else {
                        draft.push(badge.name)
                      }
                    }
                    )
                    setValueChange(newValue)
                  }} labelColor={badge.color} isBadge />
                <span className="flex-grow-1 text-right" style={{ color: '#9497AB' }}>
                  {counts[badge.name] ?? 0}
                </span>
              </div>
            )
          }

        </div>

      </div>
    </div>
  )
}
