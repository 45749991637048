import './preference.scss'
import React, { useEffect, useState } from 'react'

import Checkbox from '../../components/Checkbox/Checkbox'

import { ReactComponent as SearchIcon } from '../../icons/search.svg'
import { ReactComponent as Close2Icon } from '../../icons/close2.svg'

import { toast } from 'react-toastify'

interface OPTION {
  label: string
  value: string
  checked?: boolean
  count?: number
}

interface Props {
  options: OPTION[]
  value: string[]
  title?: string
  titleSubHeading?: string
  onChange: (selected: string[]) => void
  currentIndex?: number
  length?: number
  clearAllSelectOption?: boolean
  requestSource?: boolean
  openUserModal?: () => void
  isModalOpened?: boolean
  selectAtLeastOne?: boolean
  isSelectAllOption?: boolean

}

export default function ModifiedMultiSelect ({ value = [], options, title, onChange, ...rest }: Props): React.ReactElement {
  const [showMenu] = useState(true)
  const [search, setSearch] = useState('')
  const [doSeeLess] = useState(false)
  const [naics, setNaics] = useState<string[]>([])

  useEffect(() => {
    setNaics(value)
  }, [])

  const onSelectionChange = (option: OPTION): void => {
    const newValue = value.includes(option.value) ? value.filter(v => v !== option.value) : [...value, option.value]
    onChange(newValue)
  }

  const onClearAll = (e: any): void => {
    setNaics([])
    onChange([])
    e.stopPropagation()
  }

  const onSelectAll = (e: any): void => {
    const values = options.map(i => {
      return i.value
    })
    onChange(values)
    e.stopPropagation()
  }

  const onSubmit = (e: any): void => {
    if (e.key === 'Enter') {
      if (e.target.value.length < 2) {
        toast.error('Please enter atleast 2 digits for NAICS code.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark'
        })
      } else {
        setNaics([...naics, e.target.value])
        onChange([...naics, e.target.value])
        setSearch('')
      }
    } else {
      setSearch(e.target?.value ?? '')
    }
  }

  return (
    <div className={'MultiSelectDropdown ' + (showMenu ? 'active' : '')}>
      {/* {(rest.currentIndex != null) && <p className='index-number' >{rest.currentIndex}/{rest.length}</p>} */}
      <div className="MultiSelectDropdown__input">
        <div className="titleContainer">
          <span className="filter-title-modified">{title}</span>
          <p className="filter-subtitle1">{rest.titleSubHeading}</p>
        </div>

      </div>
      <div className='collapse-menu'>
        {rest.currentIndex === 1 && (
          <div className="dropdown-search">
            <input type="text" value={search} placeholder="Search NAICS code" onChange={(e: any) => { setSearch(e.target?.value ?? '') }} onKeyDown={onSubmit} />
            <SearchIcon />
          </div>
        )}
        <div className='d-flex'>
          {rest.currentIndex === 1 && (<a onClick={onClearAll} className="clearSelect">Remove All</a>)}
          {rest.currentIndex !== 1 && (rest.isSelectAllOption ?? false) && <a onClick={onSelectAll} className='clearSelect' >Select All </a>}
          {rest.currentIndex !== 1 && (rest.clearAllSelectOption ?? false) && <a onClick={onClearAll} className="clearSelect">Deselect All</a>}
        </div>
        {rest.currentIndex === 1 && (
          <div className='d-flex naics-wrapper'>
            {naics?.map((item, index) => (
              <div className='naics-item' key={index} onClick={() => {
                setNaics(naics?.filter(ite => ite !== item))
                onChange(naics?.filter(ite => ite !== item))
              }}>{item} <Close2Icon /></div>
            ))}
          </div>
        )}
        {rest.currentIndex !== 1 && (<ul>
          {options.filter(o => (search.length > 0) ? o.label.toLowerCase().includes(search.toLowerCase()) : true).map((option, index) =>
            ((!doSeeLess || (doSeeLess && index < 5)) && <li key={option.value + String(index)}>
            <Checkbox checked={value.includes((option.value))} label={option.label} onChange={() => { onSelectionChange(option) }} labelColor="#415473" />
          </li>
            ))}
        </ul>)}
      </div>
      {/* {options.length > 5 && doSeeLess && <p className='show-all' onClick={() => { setSeeLess(false) }} >Show All({options.length})</p>}
            {options.length > 5 && !doSeeLess && <p className='show-all' onClick={() => { setSeeLess(true) }} >Show Less</p>} */}

      {/* {(rest.requestSource ?? false) && (
                <div className="source-request-container">
                    <p className="source-content" >
                        Want a source that we don’t have?<br></br>
                        Request a new source and we’ll get on it for you
                    </p>
                </div>
            )} */}
    </div>
  )
}
