import './Header.scss'
import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'

import mixpanel from '../../utils/mixpanel'
import { useAppDispatch } from '../../hooks/useReduxToolkit'
import { logOutUser } from '../../core/store/user/userSlice'

import { ReactComponent as Logo1Icon } from '../../icons/logo1.svg'
import { ReactComponent as BarsSolid } from '../../icons/bars-solid.svg'
import { ReactComponent as CloseIcon } from '../../icons/close1.svg'

const LINKS = [
  {
    label: 'Dashboard',
    href: '/'
  },
  {
    label: 'Settings',
    href: '/settings'
  }
]

interface Props {
  bannerText?: string
  bannerButtonText?: string
  billingUrl?: string
  bannerVisible?: boolean
}

export default function Header ({
  bannerText = '',
  bannerButtonText = 'Start Free Trial',
  bannerVisible = false,
  billingUrl = process.env.REACT_APP_STRIPE_URL
}: Props): React.ReactElement {
  const [isBannerVisible, setIsBannerVisible] = useState(bannerVisible)

  const handleCloseBanner = (): void => {
    setIsBannerVisible(false)
  }

  const dispatch = useAppDispatch()

  const [menuOpened, setMenuOpened] = useState<boolean>(false)

  const handleLogout = (e: any): void => {
    dispatch(logOutUser())
    e.stopPropagation()
    setMenuOpened(false)
    try {
      mixpanel.track('Logout')
    } catch (error) { }
    window.location.reload()
  }
  return (
    <>
    <div className="Header">
      <div className="container d-flex align-items-center justify-content-between h-100">
          <Link onClick={() => { }} to="/">
            <Logo1Icon style={{ height: 50 }}/>
          </Link>
        <BarsSolid className='mobile-menu-toggler' onClick={() => { setMenuOpened(!menuOpened) }}></BarsSolid>
        <div className={'row row-cols-auto mobile-menu ' + (menuOpened ? 'open' : '')}>
          {LINKS.map((l, index) => (
            <div className="col-12 col-lg flex-shrink-0" key={l.label}>
              <NavLink
                to={l.href}
                onClick={() => { setMenuOpened(false); mixpanel.track(l.label) }}
                className="fw-normal text-decoration-none link-white"
              >
                {l.label}
              </NavLink>
            </div>
          ))}
          {
          <div className="col-12 col-lg flex-shrink-0">
              <NavLink
                onClick={handleLogout}
                to="/"
                className="fw-bold text-decoration-none link-white"
              >
                Log Out
              </NavLink>
          </div>
            }
        </div>
      </div>
    </div>
    {isBannerVisible && (
      <div className='Trial d-flex'>
      <div className='container d-flex align-items-center justify-content-between h-100 Trial__container'>
        <span> {bannerText}</span>
        <button onClick={(event) =>
          window.open(billingUrl, '_blank')
          }>{bannerButtonText}</button>
        <CloseIcon onClick={handleCloseBanner}/>
      </div>
      </div>
    )}
  </>
  )
}
