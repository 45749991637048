import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Switch from 'react-switch'
import { useAppSelector, useAppDispatch } from '../../hooks/useReduxToolkit'
import Loader from '../../components/Loader/Loader'

import { updateUserDetail } from '../../core/store/user/userSlice'
import { BADGE_COLORS } from '../../core/data'
import Checkbox from '../../components/Checkbox/Checkbox'

export default function NotificationSetting (): React.ReactElement {
  const dispatch = useAppDispatch()
  const user = useAppSelector((s) => s.user.user)

  const [loading] = useState(false)
  const [sendDailyEmails, setSendDailyEmails] = useState(user.sendDailyUpdate) // State for toggle button
  const [selectedRightFitScores, setSelectedRightFitScores] = useState(user.emailRightFitRfpScores)
  const [updateUser, setUpdateUser] = useState(false)

  const handleToggle = (checked: boolean): void => {
    if (!checked) {
      setSelectedRightFitScores([])
    } else {
      if (selectedRightFitScores.length === 0) {
        setSelectedRightFitScores(['A'])
      }
    }
  }

  const handleBadgeChange = (badgeName: string, isChecked: boolean): void => {
    setSelectedRightFitScores(prevSelected => {
      if (isChecked) {
        // Add the badge to the list if checked
        return [...prevSelected, badgeName]
      } else {
        // Remove the badge from the list if unchecked
        return prevSelected.filter(name => name !== badgeName)
      }
    })
  }

  useEffect(() => {
    if (selectedRightFitScores.length === 0) {
      if (sendDailyEmails) {
        setSendDailyEmails(false)
      }
    } else {
      if (selectedRightFitScores !== user.emailRightFitRfpScores) {
        if (!sendDailyEmails) {
          setSendDailyEmails(true)
        } else {
          setUpdateUser(true)
        }
      }
    }
    // console.log(selectedRightFitScores)
  }, [selectedRightFitScores])

  useEffect(() => {
    if (sendDailyEmails !== user.sendDailyUpdate) {
      setUpdateUser(true)
    }
  }, [sendDailyEmails])

  useEffect(() => {
    if (updateUser) {
      void dispatch(updateUserDetail({
        name: user.name,
        lastname: user.lastname,
        company: user.company,
        send_daily_update: sendDailyEmails,
        email_right_fit_rfp_scores: selectedRightFitScores
      })).then(() => {
        console.log('Notifications settings updated successfully')
        toast.success('Notifications changes saved successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark'
        })
        setUpdateUser(false)
      }).catch((error) => {
        console.error('Error resending confirmation code:', error)
        // Handle error (e.g., show error message to user)
        toast.error('Error saving notification changes!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark'
        })
        setUpdateUser(false)
      })
    }
  }, [updateUser])

  return (
    <>
      {loading && <Loader />}

      <div>
        {/* <stripe-pricing-table
          pricing-table-id="prctbl_1NE7WXBOOSdBnYBP2OoNGnxJ"
          publishable-key="pk_test_rfSj3EuphA1xgZt2R3DI3CLy"
          client-reference-id={user?.companyId}
        ></stripe-pricing-table> */}
        {/* Yes/No slider for "Send Daily update emails" */}
        <label className='notification-toggle-label'>
          <span className='notification-toggle-text'>Send Daily email updates: </span>
          <Switch
            onChange={handleToggle}
            checked={sendDailyEmails}
            onColor="#FF8533"
            onHandleColor="#FFFFFF"
            handleDiameter={15}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
            height={20}
            width={40}
            className="react-switch"
            id="daily-update-switch"
          />
        </label>
        <div className=" row rfp__detail__body__aside__badge-container ">
        {
            BADGE_COLORS.map((badge) => (
              <div key={badge.name} className="col-6 mt-3 d-flex badges">
                <Checkbox
                  type={0}
                  checked={selectedRightFitScores.includes(badge.name)}
                  label={badge.name}
                  onChange={(e) => { handleBadgeChange(badge.name, e.target.checked) }}
                  labelColor={badge.color}
                  isBadge
                />
                <span className="flex-grow-1 text-center" style={{ color: '#9497AB' }}></span>
              </div>
            ))
          }
                  </div>
      </div>
    </>
  )
}
