import './RfpDetail.scss'
import React, { useEffect, useRef, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks/useReduxToolkit'

import { toast } from 'react-toastify'
import { cloneDeep } from 'lodash'

import { ReactComponent as CalenderIcon } from '../../icons/calender.svg'
import { ReactComponent as DollarIcon } from '../../icons/dollar.svg'
import { ReactComponent as ListIcon } from '../../icons/list.svg'
import { ReactComponent as AccountIcon } from '../../icons/account.svg'
import { ReactComponent as GlobalIcon } from '../../icons/global.svg'
import { ReactComponent as Close1Icon } from '../../icons/close1.svg'
import { ReactComponent as LocationIcon } from '../../icons/location.svg'
import { ReactComponent as ArrowsLeftIcon } from '../../icons/arrows-left.svg'
import { ReactComponent as EyeSlashIcon } from '../../icons/eye-slash.svg'
import { ReactComponent as Check2Icon } from '../../icons/check2.svg'
import { ReactComponent as StarIcon } from '../../icons/star.svg'
import { ReactComponent as UprightFromIcon } from '../../icons/up-right-from.svg'
import { ReactComponent as Check3Icon } from '../../icons/check3.svg'
import { ReactComponent as Star2Icon } from '../../icons/star2.svg'

import Modal from '../../components/Modal'
import Loader from '../../components/Loader/Loader'
import RatingBadge from '../../components/RatingBadge/RatingBadge'
import Checkbox from '../../components/Checkbox/Checkbox'
import FileIcon from '../../components/FileIcon/FileIcon'

import { useModal } from '../../hooks/useModal'
import { changeIcon, fetchStatusCounts } from '../../core/store/dashboard/dashboardSlice'
import axiosIct from '../../utils/axios'
import { BADGE_COLORS } from '../../core/data'
import mixpanel from '../../utils/mixpanel'
import { RoutesEnum } from '../../core/enums'
import { useIndexedDB } from '../../core/indexedDBService'

interface IRFP {
  'budget'?: string
  'due_date'?: string
  'found_date'?: string
  'friendly_name'?: string
  'rfp_source'?: string
  'right_fit_score'?: string
  'right_fit_score_confirmed'?: 'Yes' | 'No'
  'id'?: string
  'naics_code'?: string
  'naics_code_full'?: string
  'page_text'?: string
  'posted_date'?: string
  'rfp_title'?: string
  'rfp_url'?: string
  's3_bucket_url'?: Array<{ file_name: string, file_key: string, checked: boolean }>
  'set_aside'?: string
}

interface IFile {
  file_name: string
  file_key: string
  checked: boolean
}

export default function RfpDetail (): React.ReactElement {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { rfpList: newFetchedData, rfpCount, total, lastEvaluatedKey } = useAppSelector(s => s.dashboard)
  const filesRef = useRef<any>(null)
  const ref2 = useRef<any>()
  const user = useAppSelector(s => s.user.user)

  const [id, setId] = useState('')
  const [reportMessage, setReportMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [rfpData, setRfpData] = useState<IRFP>({})
  const [apply, setApply] = useState(false)
  const [hide, setHide] = useState(false)
  const [favorite, setFavorite] = useState(false)
  const [files, setFiles] = useState<IFile[]>([])
  const [selectedOption, setSelectedOption] = useState(0)

  const [selectedBadge, setSelectedBadge] = useState<string>('')
  const { id: slug = '' } = useParams<{ id: string }>()
  const { rfpRightFitScore = '' } = useParams<{ rfpRightFitScore: string }>()
  // Usage example:
  const [currentRfpData, setCurrentRfpData] = useIndexedDB('currentRfpData', newFetchedData)

  const {
    isModalOpened,
    closeModal,
    openModal
  } = useModal()

  const {
    isModalOpened: isReportModalOpened,
    closeModal: closeReportModal,
    openModal: openReportModal
  } = useModal()

  useEffect(() => {
    if (ref2.current !== undefined) {
      ref2.current.indeterminate = true
    }

    axiosIct.get(`/data/rfp/${slug}?right_fit_score=${rfpRightFitScore}`).then((r: any) => {
      setLoading(true)
      setId(r.data.id ?? '')
      setRfpData(r.data)
      setFiles(r.data.s3_bucket_url.map((file: any) => ({ ...file, checked: false })))
      setFavorite(r.data.actions.favorite)
      setHide(r.data.actions.hide)
      setApply(r.data.actions.apply)
      setLoading(false)
      return r
    }).then((r) => {
      if (r.data.page_text === '' && (Boolean(r.data.rfp_url))) {
        window.open(r.data.rfp_url, '_blank')
      }
    }).catch(e => {
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent): void => {
      // Cancel the event (for cross-browser support)
      event.preventDefault()
      // Chrome requires returnValue to be set
      event.returnValue = ''
      // Perform cleanup or save data actions here
      console.log('Performing cleanup actions...')
      // For example, save form data to localStorage
      updateActions(id, { apply: apply, hide: hide, favorite: favorite })
    }
    // Add event listener for beforeunload
    window.addEventListener('beforeunload', handleBeforeUnload)

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  const handleFileCheck = (isChecked: boolean, index: number): void => {
    const newFiles: any[] = [...files]
    newFiles[index].checked = isChecked
    setFiles(newFiles)
  }

  //  Define a function to update actions based on item ID and index range
  const updateActions = (
    itemID: string,
    newActions: { apply: boolean, favorite: boolean, hide: boolean }
  ): void => {
    console.log(newFetchedData, lastEvaluatedKey, rfpCount, total)

    if (user.userId.length > 0) {
      // Calculate the index range for the current page
      const startIndex = parseInt(sessionStorage.getItem('startIndex') ?? '-1')
      const endIndex = parseInt(sessionStorage.getItem('endIndex') ?? '25')

      if (hide) {
        const index = currentRfpData.findIndex(item => item.id === id)
        if (index !== -1) {
          const updatedData = [
            ...currentRfpData.slice(0, index),
            ...currentRfpData.slice(index + 1)
          ]
          setCurrentRfpData(updatedData)
        }
      } else {
        const newData = currentRfpData.slice() // Create a shallow copy of the array
        for (let i = startIndex; i <= endIndex; i++) {
          const item = newData[i]
          if (item !== null && item.id === itemID) {
            const newItem = cloneDeep(item) // Create a deep copy of the item
            newItem.actions = { ...newItem.actions, ...newActions }
            newData[i] = newItem // Update the item in place
            setCurrentRfpData(newData)
            break
          }
        }
      }
    }
  }

  const indeterminate = files.some(file => file.checked) && !files.every(file => file.checked)

  const isSelectAllChecked = files.every(file => file.checked)

  const selectAll = (isChecked: boolean): void => {
    const newFiles = [...files]
    newFiles.forEach(file => { file.checked = isChecked })
    setFiles(newFiles)
  }

  // method for right fit score feedback
  const postScore = (score: string): void => {
    void axiosIct.post(`/data/rfp/${id}/right_fit/${score}`).then(() => {
      mixpanel.track('Action Right-Fit Score')
    })
  }

  // handle toggleicon
  const handleToggleIcon = async (event: React.MouseEvent | null, toggleIcon: 'apply' | 'hide' | 'favorite', val: boolean, itemId: string): Promise<void> => {
    let actionMessage: any = toggleIcon

    const requestBody = [
      {
        action: toggleIcon,
        value: !val
      }
    ]

    if (toggleIcon === 'apply') {
      setApply(!val)
      setFavorite(false)
      setHide(false)
      actionMessage = 'Action Apply'
      requestBody.push({ action: 'hide', value: false }, { action: 'favorite', value: false })
    }
    if (toggleIcon === 'favorite') {
      setApply(false)
      setFavorite(!val)
      setHide(false)
      actionMessage = 'Action Favorite'
      requestBody.push({ action: 'hide', value: false }, { action: 'apply', value: false })
    }
    if (toggleIcon === 'hide') {
      setApply(false)
      setFavorite(false)
      setHide(!val)
      actionMessage = 'Action Hide'
      requestBody.push({ action: 'favorite', value: false }, { action: 'apply', value: false })
    }

    await axiosIct.post(`/data/rfp/${itemId}/action`, requestBody).then(r => {
      dispatch(changeIcon({ val: toggleIcon === 'apply' ? !val : false, itemId, toggleIcon: 'apply' }))
      return { val, itemId, toggleIcon }
    }).then(r => {
      mixpanel.track(actionMessage)
    }).catch(e => {

    })

    //   setLoading(true)
    // await axiosIct.post(`/data/rfp/${itemId}/action`, {
    //   action: 'hide',
    //   value: toggleIcon === 'hide' ? !val : false
    // }).then(r => {
    //   dispatch(changeIcon({ val: toggleIcon === 'hide' ? !val : false, itemId, toggleIcon: 'hide' }))
    //   return { val, itemId, toggleIcon }
    // }).then(r => {
    //   mixpanel.track(actionMessage)
    // }).catch(e => {

    // })

    // //   setLoading(true)
    // await axiosIct.post(`/data/rfp/${itemId}/action`, {
    //   action: 'favorite',
    //   value: toggleIcon === 'favorite' ? !val : false
    // }).then(r => {
    //   dispatch(changeIcon({ val: toggleIcon === 'favorite' ? !val : false, itemId, toggleIcon: 'favorite' }))
    //   return { val, itemId, toggleIcon }
    // }).then(r => {
    //   mixpanel.track(actionMessage)
    // }).catch(e => {

    // })

    void dispatch(fetchStatusCounts(rfpCount))
    //   setLoading(false);
    if (event != null) {
      event.stopPropagation()
    }
  }

  const handleFileDownload = async (event: any, fileKey: string, fileName: string): Promise<void> => {
    try {
      event?.stopPropagation()
      const { data: { presigned_url: presignedUrl } } = await axiosIct.post('/data/file', { file_key: fileKey })
      const link = document.createElement('a')
      const token = localStorage.getItem('id_token')
      if (token === null) {
        navigate(RoutesEnum.LoginMagicLink)
      } else {
        link.href = presignedUrl
        link.download = fileName
        link.click()
        await new Promise((resolve) => setTimeout(resolve, 1000))
      }
    } catch (err) {
      console.log(err)
      toast.error('Please View the Opportunity to Download Files', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      })
    }
    event.preventDefault()
    mixpanel.track('Files Download')
  }

  const handleDownloadAll = async (): Promise<void> => {
    const selectedFiles = files.filter(f => f.checked)
    for (const file of selectedFiles) {
      try {
        const { data: { presigned_url: presignedUrl } } = await axiosIct.post('/data/file', { file_key: file.file_key })
        const link = document.createElement('a')
        const token = localStorage.getItem('id_token')
        if (token === null) {
          navigate(RoutesEnum.LoginMagicLink)
        } else {
          link.href = presignedUrl
          link.download = file.file_name
          link.click()
          await new Promise((resolve) => setTimeout(resolve, 1000))
        }
      } catch (err) {
        console.log(err)
        toast.error('Please View the Opportunity to Download Files', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark'
        })
      }
    }
    mixpanel.track('Files Download')
  }

  const viewOpportunity = (): void => {
    window.open(rfpData?.rfp_url, '_blank')
    if (!apply) {
      openModal()
    }
    mixpanel.track('View Opportunity')
  }

  const submitReport = (): void => {
    const requestBody = {
      message: reportMessage
    }
    axiosIct.post(`/data/rfp/${id}/report`, requestBody).then(() => {
      closeReportModal()
    }).catch(() => { }).finally(() => {
      setReportMessage('')
    })
    mixpanel.track('RFP Report')
  }

  const shareContent = async (): Promise<void> => {
    try {
      const data = {
        title: rfpData?.rfp_title,
        url: window.location.host + '?redirectUrl=' + window.location.href
      }
      console.log(data)
      if (navigator.canShare?.(data)) {
        await navigator.share(data)
      } else {
        console.log('Web Share API not supported, copying to clipboard')
        await navigator.clipboard.writeText(data.url)
        toast.success('Rfp Share URL Copied Successfully to clipboard', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark'
        })
      }

      // if (navigator.canShare(data)) {
      //   await navigator.share(data)
      // } else {
      //   console.log('Web Share API not supported')
      // }
    } catch (error) {
      console.error('Error sharing:', error)
    }
    mixpanel.track('RFP Share')
  }

  return (
    <div className="container py-4 py-lg-5">
      <div className="rfp__detail">
        {loading && <Loader></Loader>}

        <div className="rfp__detail__header">
          <h3 className="rfp__detail__header__title" onClick={() => { updateActions(id, { apply: apply, hide: hide, favorite: favorite }); navigate('/') }}>
            <ArrowsLeftIcon />
            Back to Dashboard
          </h3>
          <Close1Icon className="cursor-pointer" onClick={() => { updateActions(id, { apply: apply, hide: hide, favorite: favorite }); navigate('/') }} />
        </div>
        <div className="rfp__detail__body">
          <div className="rfp__detail__body__aside">
            <h3 className="rfp__detail__body__aside__title">Overview</h3>
            <div className="rfp__detail__body__aside__item border-bottom">
              <CalenderIcon />
              <span>
                <b>Due Date</b>
                {((rfpData?.due_date) != null) ? new Date(rfpData?.due_date).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }) : 'Check RFP Details'}
              </span>
            </div>
            <div className="rfp__detail__body__aside__item border-bottom">
              <DollarIcon />
              <span>
                <b>Amount</b>
                {''}
              </span>
            </div>
            { rfpData.rfp_source !== 'sam.gov' && rfpData.naics_code_full !== '' &&
            <div className="rfp__detail__body__aside__predict" onClick={() => window.open('https://oneclickrfp.com/terms', '_blank')}>
              <span>
                One Click Predict
              </span>
              </div>
            }

            <div className="rfp__detail__body__aside__item border-bottom">
              <ListIcon />
              <span>
                <b>NAICS Code</b>
                {rfpData.naics_code_full}
              </span>
            </div>
            <div className="rfp__detail__body__aside__item border-bottom">
              <AccountIcon />
              <span>
                <b>Set-Aside</b>
                {rfpData?.set_aside}
              </span>
            </div>
            <div className="rfp__detail__body__aside__item border-bottom">
              <LocationIcon />
              <span>
                <b>Place Of Performance</b>
                {rfpData?.friendly_name}
              </span>
            </div>
            <div className="rfp__detail__body__aside__item border-bottom">
              <GlobalIcon />
              <span>
                <b>RFP Source</b>
                {rfpData?.rfp_source}
              </span>
            </div>
            <div className="rfp__detail__body__aside__item">
              <RatingBadge letter={rfpData.right_fit_score ?? 'E'} />
              <span>
                <b>Right Fit RFP Score</b>
              </span>
            </div>
            <div className="border-bottom pb-4">
              {selectedOption === 0 &&
                <>
                  { rfpData?.right_fit_score_confirmed === 'Yes'
                    ? (
                      <>
                          <div className="rfp__detail__body__aside__buttons">
                            <span onClick={(e) => { setSelectedOption(2); e.stopPropagation() }} >Change</span>
                          </div>
                      </>
                      )
                    : (
                      <>
                        <div className="rfp__detail__body__aside__query">
                          Help us determine the right fit for you. Does this score seem accurate?
                        </div>
                        <div className="rfp__detail__body__aside__buttons">
                          <span onClick={() => { setSelectedOption(1); postScore(rfpData.right_fit_score ?? 'E') }} >Yes</span>
                          <span onClick={(e) => { setSelectedOption(2); e.stopPropagation() }} >No</span>
                        </div>
                      </>
                      )
              }
                </>
              }
              {selectedOption === 1 &&
                <>
                <div className="rfp__detail__body__aside__buttons">
                  <span onClick={(e) => { setSelectedOption(2); e.stopPropagation() }} >Change</span>
                </div>
              </>
              }
              {selectedOption === 2 &&
                <>
                  <div className="rfp__detail__body__aside__query">
                    Help us determine the right fit for you. Does this score seem accurate?
                  </div>
                  <div className=" row rfp__detail__body__aside__badge-container ">
                    {
                      BADGE_COLORS.map((badge, index) =>
                        <div key={badge.name} className="col-6 mt-3 d-flex badges ">
                          <Checkbox type={1} checked={selectedBadge === badge.name} label={badge.name}
                            onChange={(e) => { setSelectedBadge(badge.name); postScore(badge.name); e.stopPropagation(); setRfpData({ ...rfpData, right_fit_score: badge.name }); setSelectedOption(1) }} labelColor={badge.color} isBadge />
                          <span className="flex-grow-1 text-center" style={{ color: '#9497AB' }}></span>
                        </div>
                      )
                    }
                  </div>
                </>
              }
            </div>
            <div className="rfp__detail__body__aside__buttons mt-4">
              <span onClick={() => { void shareContent() }}>Share</span>
              <span onClick={openReportModal}>Report</span>
            </div>
          </div>
          <div className="rfp__detail__body__content">
            <div className="rfp__detail__body__content__header">
              <div className="rfp__detail__body__content__header_left">
                <div className='post-box-wrapper'>
                  {apply && (<div className="post-box__applied">
                    APPLIED<Check3Icon />
                  </div>)}
                  {favorite && (<div className="post-box__favorite">
                    SAVED<Star2Icon />
                  </div>)}
                  {hide && (<div className="post-box__hide">
                    HIDDEN<EyeSlashIcon />
                  </div>)}
                </div>
              </div>
              <div className="rfp__detail__body__content__header_right">
                <Check2Icon className={(apply ? 'active' : '') + ' toggle-icon'} onClick={(event: React.MouseEvent<Element, MouseEvent> | null) => {
                  void handleToggleIcon(event, 'apply', apply, id)
                }} />

                <EyeSlashIcon onClick={(event: React.MouseEvent<Element, MouseEvent> | null) => {
                  void handleToggleIcon(event, 'hide', hide, id)
                }} className={(hide ? 'active' : '') + ' toggle-icon'} />

                <StarIcon onClick={(event: React.MouseEvent<Element, MouseEvent> | null) => {
                  void handleToggleIcon(event, 'favorite', favorite, id)
                }}
                  className={(favorite ? 'active' : '') + ' toggle-icon'} />
              </div>
            </div>
            <h2 className="rfp__detail__body__content__title">
              {rfpData?.rfp_title}
            </h2>
            <div className="rfp__detail__body__content__actions">
              {/* <button className="btn2">Details</button> */}
              {
                ((files?.length) > 0) &&
                <button className="btn2 btn2-outline ms-lg-2" onClick={() => { filesRef.current?.scrollIntoView({ behavior: 'smooth' }) }}>View Files</button>
              }
              {
                !((rfpData?.rfp_url) == null) &&
                <button className="btn2 btn2-outline ms-lg-2" onClick={viewOpportunity}>View Opportunity <UprightFromIcon /></button>
              }
            </div>
            <h6 className="heading-title-2">Details</h6>
            <div className="rfp__body__text" dangerouslySetInnerHTML={{ __html: rfpData?.page_text ?? '' }}>
            </div>
            {
              !((files?.length) === 0) &&
              <>
                <h6 className="heading-title-2 my-5" ref={filesRef}>Files</h6>
                <table className="rfp-file-table">
                  <thead className="border-bottom">
                    <tr>
                      <th><Checkbox checked={isSelectAllChecked} indeterminate={indeterminate} label={''} onChange={(e: any) => { selectAll(e.target.checked) }} theme='primary' /></th>
                      <th></th>
                      <th>File Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {files.map((file, index: number) => <tr key={file.file_key}>
                      <td><Checkbox checked={file.checked} label={''} onChange={(e: any) => { handleFileCheck(e.target.checked, index) }} theme='primary' /></td>
                      <td><FileIcon filename={file.file_name} /></td>
                      <td>
                        <span onClick={(e) => { void handleFileDownload(e, file.file_key, file.file_name) }}>{file.file_name}</span>
                      </td>
                    </tr>
                    )}
                  </tbody>
                </table>
                <div className="border-bottom py-4">
                  <button className="btn" onClick={() => { void handleDownloadAll() }}>Download</button>
                </div></>
            }
            <div className="rfp__detail__body__content__actions justify-content-between mt-3">
            <button className="btn2 btn2-outline" onClick={() => { window.scrollTo({ top: 0, behavior: 'smooth' }) }}>Back to Top</button>
              {
                ((rfpData?.rfp_url) != null) &&
                <button className="btn2-empty" onClick={viewOpportunity}>View Opportunity <UprightFromIcon /></button>
              }
            </div>
          </div>
        </div>

        <Modal isOpened={isModalOpened} closeModal={closeModal} title="">
          <h2 className="modal__applied__title">Did  you apply to this RFP?</h2>
          <div className="modal__applied__text">
            Letting us know will mark this RFP as “Applied” and <br />
            improve our ability to find your Right-Fit RFP.
          </div>
          <div className="modal__applied__buttons">
            <button className="btn5 btn5-outline" onClick={() => { closeModal() }}>No</button>
            <a className="btn" onClick={() => { closeModal(); void handleToggleIcon(null, 'apply', apply, id) }}>Yes, mark as applied</a>
          </div>
        </Modal>

        <Modal isOpened={isReportModalOpened} closeModal={closeReportModal} title="">
          <h2 className="modal__applied__title">Report</h2>
          <div className="modal__applied__text">
            Please let us know about any RFP issues you are experiencing, <br /> and we will gladly assist you in resolving them.
          </div>
          <form style={{ width: '90%', margin: 'auto' }} >
            <p className="fw-bold mb-2">Message</p>
            <input className='w-100 p-3 sourceInput ' value={reportMessage} onChange={(e) => { setReportMessage(e.target.value) }} />
            <div className="modal__applied__buttons  ">
              <button type="button" className="btn request-button " onClick={submitReport}>Submit</button>
            </div>
          </form>
        </Modal>
      </div>
    </div>
  )
}
