import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { type RfpListType } from '../../types'
import axiosIct from '../../../utils/axios'

interface DashboardSlice {
  rfpList: RfpListType
  rfpCount: any
  total: number
  loading: boolean
  error: string
  rfpSources: string[]
  rfpCalledOnce: boolean
  lastEvaluatedKey: string
}

const rfpList = localStorage.getItem('rfpList')
const rfpCount = localStorage.getItem('rfpCount')
const total = +(localStorage.getItem('rfpCount') ?? 0)
const rfpSourceCache = localStorage.getItem('rfpSources')

let parsedRfpList, parsedRfpCount, parsedRfpSources

const defaultStatus = {
  status: {},
  right_fit_score: {},
  source: {},
  pop: {},
  naics: {},
  due_date: {}
}

try {
  parsedRfpList = (rfpList !== null && rfpList !== '') ? JSON.parse(rfpList) : []
} catch (e) {
  console.error('Error parsing rfpList:', e)
  parsedRfpList = []
}

try {
  parsedRfpCount = (rfpCount !== null && rfpCount !== '') ? { ...defaultStatus, ...JSON.parse(rfpCount) } : defaultStatus
} catch (e) {
  console.error('Error parsing rfpCount:', e)
  parsedRfpCount = defaultStatus
}

try {
  parsedRfpSources = (rfpSourceCache !== null && rfpSourceCache !== '') ? JSON.parse(rfpSourceCache) : []
} catch (e) {
  console.error('Error parsing rfpSources:', e)
  parsedRfpSources = []
}

const initialState: DashboardSlice = {
  loading: false,
  total,
  // rfpList: (rfpList != null) ? JSON.parse(rfpList) : [],
  rfpList: parsedRfpList,
  // rfpCount: (rfpCount != null) ? { ...defaultStatus, ...JSON.parse(rfpCount) } : defaultStatus,
  rfpCount: parsedRfpCount,
  error: '',
  // rfpSources: (rfpSourceCache != null) ? JSON.parse(rfpSourceCache) : [],
  rfpSources: parsedRfpSources,
  rfpCalledOnce: false,
  lastEvaluatedKey: ''
}

export const fetchDashboardRfpList = createAsyncThunk('dashboard/fetchDashboardRfpList', async (params: any = {}) => {
  return await axiosIct.get('/data/rfp', { params }).then((r) => {
    console.log(r.data)
    return r.data
  })
})
export const fetchSources = createAsyncThunk('dashboard/sources', async () => {
  return await axiosIct.get('/data/source').then((r) => {
    console.log(r.data)
    return r.data
  })
})

export const fetchStatusCounts = createAsyncThunk('dashboard/statuscount', async (rfpcount: any) => {
  return await axiosIct.post('/data/statuscount', rfpcount).then((r) => {
    console.log(r.data)
    return r.data
  })
})

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    changeIcon: (state, action) => {
      const icon: 'hide' | 'apply' | 'favorite' = action.payload.toggleIcon
      const val = action.payload.val
      state.rfpList = state.rfpList.map(item => {
        if (item.id === action.payload.itemId) {
          item.actions[icon] = val
        }
        return item
      })
    },
    setRfpList: (state, action) => {
      state.rfpList = action.payload
    },
    setRfpCalledOnce: (state, action) => {
      state.rfpCalledOnce = action.payload
    }

  },

  extraReducers: (builder) => {
    builder.addCase(fetchDashboardRfpList.pending, (state) => {
      state.loading = !((state.rfpList.length > 0))
      state.error = ''
    })
    builder.addCase(fetchDashboardRfpList.fulfilled, (state, action) => {
      state.loading = false
      state.rfpList = action.payload.rfpList
      state.rfpCount = action.payload.rfpCount
      state.total = action.payload.total
      state.error = ''
      state.lastEvaluatedKey = action.payload.lastEvaluatedKey
    })
    builder.addCase(fetchDashboardRfpList.rejected, (state, action) => {
      state.loading = false
      state.rfpList = []
      state.error = (action.error.message != null) ? action.error.message : 'API Error'
    })
    builder.addCase(fetchSources.pending, (state, action) => {
      // state.rfpSources = []
    })
    builder.addCase(fetchSources.rejected, (state, action) => {
      state.rfpSources = []
    })
    builder.addCase(fetchSources.fulfilled, (state, action) => {
      state.rfpSources = action.payload
      localStorage.setItem('rfpSources', JSON.stringify(action.payload))
    })
    builder.addCase(fetchStatusCounts.fulfilled, (state, action) => {
      state.rfpCount = action.payload
    })
  }
})

export default dashboardSlice.reducer
export const { changeIcon, setRfpList, setRfpCalledOnce } = dashboardSlice.actions
