export function downloadFile (href: string, name: string): void {
  const link = document.createElement('a')
  link.href = href
  link.download = name
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const isEmail = (value: string): boolean => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value)

export const formatNumber = (
  price: number,
  options?: Intl.NumberFormatOptions
): string => {
  return new Intl.NumberFormat('en', {
    ...((options != null)
      ? {
          ...options,
          style: (options.currency != null) ? 'currency' : options.style
        }
      : {})
  }).format(price)
}

export function lockScroll (isLocked: boolean): void {
  document.documentElement.style.overflow = isLocked ? 'hidden' : ''
}

export const isPasswordValid = (value: string): boolean =>
  containsCapital(value) &&
  containsNonCapital(value) &&
  containsDigit(value) &&
  containsSpecialCharacter(value) &&
  noSpacesOnEnds(value) &&
  isPasswordLongEnough(value)

export function noSpacesOnEnds (v: string): boolean {
  return v === v.trim()
}

export function containsCapital (v: string): boolean {
  return /[A-Z]/.test(v)
}
export function containsNonCapital (v: string): boolean {
  return /[a-z]/.test(v)
}
export function containsDigit (v: string): boolean {
  return /\d/.test(v)
}
export function containsSpecialCharacter (v: string): boolean {
  const characters = [
    '^',
    '$',
    '*',
    '.',
    '[',
    ']',
    '{',
    '}',
    '(',
    ')',
    '?',
    '"',
    '!',
    '@',
    '#',
    '%',
    '&',
    '/',
    '\\',
    ',',
    '>',
    '<',
    "'",
    ':',
    ';',
    '|',
    '_',
    '~',
    '`',
    '=',
    '+',
    '-'
  ]

  return !(v.split('').filter((c) => characters.includes(c)).length === 0)
}
export function isPasswordLongEnough (password: string): boolean {
  return password.length >= 8
}
