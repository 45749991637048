import React, { useEffect, useState } from 'react'
import { type FormikErrors, useFormik } from 'formik'
import { Link } from 'react-router-dom'

import { useAppDispatch } from '../../hooks/useReduxToolkit'

import LoginContainer from '../../containers/LoginContainer'
import { loginUser } from '../../core/store/user/userSlice'

import Input from '../../components/Input/Input'

import { RoutesEnum } from '../../core/enums'
import { toast } from 'react-toastify'
import axiosIct from '../../utils/axios'

interface FormikValues {
  code: string
}

export default function ConfirmAccount (): React.ReactElement {
  const dispatch = useAppDispatch()
  const formik = useFormik({
    initialValues: {
      code: ''
    },
    validate: (values) => {
      const errors: FormikErrors<FormikValues> = {}
      if (values.code.length === 0) {
        errors.code = 'Required'
      }
      return errors
    },
    onSubmit: (values) => {
      const payload = {
        code: values.code,
        email: localStorage.getItem('email'),
        password: localStorage.getItem('password')
      }

      void axiosIct.post('/user/confirm-account', payload).then(() => {
        localStorage.setItem('showSetupPreferences', 'true')
        void dispatch(loginUser({
          email: payload.email,
          password: payload.password
        }))
        localStorage.removeItem('password')
      })
    }
  })
  const [timer, setTimer] = useState(60) // Timer state in seconds
  const [resendCount, setResendCount] = useState(0) // Resend count state

  // Function to start the countdown timer
  const startTimer = (): void => {
    setTimer(60) // Set timer to 60 seconds

    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1)
    }, 1000)

    // Clear interval when timer reaches 0
    setTimeout(() => {
      clearInterval(interval)
      setResendCount((prevCount) => prevCount + 1) // Increment resend count
      setTimer(0) // Reset timer to 0
    }, 60000)
  }

  // Function to handle resend confirmation code
  const handleResendCode = (): void => {
    if (resendCount < 2) {
      resendConfirmationCode()
      startTimer() // Start the timer
    }
  }

  const handleManualConfirmation = (): void => {
    const payload = {
      email: localStorage.getItem('email')
    }

    axiosIct.post('/user/admin-confirm-account', payload)
      .then(() => {
        console.log('Email has been Confirmed successfully')
        toast.success('Email has been Confirmed successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark'
        })
        localStorage.setItem('showSetupPreferences', 'true')
        void dispatch(loginUser({
          email: payload.email,
          password: localStorage.getItem('password')
        }))
        localStorage.removeItem('password')
      })
      .catch((error) => {
        console.error('Error Confirming Email ', error)
        // Handle error (e.g., show error message to user)
        toast.error('Sorry for the incovenience. Please Contact support@oneclickrfp.com', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark'
        })
      })
  }

  // Function to resend confirmation code
  const resendConfirmationCode = (): void => {
    const payload = {
      email: localStorage.getItem('email')
    }

    axiosIct.post('/user/resend-code', payload)
      .then(() => {
        console.log('Confirmation code resent successfully')
        toast.success('Confirmation Code Sent Successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark'
        })
      })
      .catch((error) => {
        console.error('Error resending confirmation code:', error)
        // Handle error (e.g., show error message to user)
        toast.error('Something went wrong!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark'
        })
      })
  }

  useEffect(() => {
    if (resendCount === 0) {
      startTimer()
    }
  }, [])

  return (
    <LoginContainer>
      <div className='ResetPassword_card Login__card px-3 d-flex flex-column align-items-center justify-content-center w-100'>
        <div className='Login__title fw-bold mb-2'>Account Verification</div>
        <div className="mb-4 Login__subtitle">Please verify your account using the code we’ve sent to your email</div>
        <form className='w-100 Login__form' onSubmit={formik.handleSubmit}>
          <div className='d-flex'>
            <Input
              onBlur={formik.handleBlur}
              value={formik.values.code}
              onChange={formik.handleChange}
              name='code'
              label='Code'
              className='mb-4 flex-grow-1'
              type='text'
              required
              error={formik.touched.code}
            />
          </div>
          {resendCount < 2 && timer === 0
            ? (
              <>
              {/* <button
                className='btn w-100 Login__btn mt-2'
                onClick={handleResendCode}
              >
                Resend Code
              </button> */}
              <div className='d-flex flex-column align-items-center justify-content-center'>
                <Link to="#" onClick={handleResendCode} className="text-end fw-bold mt-1 mb-1" >
                  Resend Code
                </Link>
              </div>
              </>
              )
            : (
              <div className='text-center mt-2 '>
                  <div>{resendCount === 0 ? 'Resend code' : 'Confirmation Without Code'} in {timer} seconds</div>
              </div>
              )}
          <div className='d-flex flex-column align-items-center justify-content-center button-container'>
          {resendCount < 2
            ? (
              <button className='btn w-100 Login__btn ' type='submit'>
                Confirm
              </button>
              )
            : (
              <button className='btn w-100 Login__btn ' onClick={handleManualConfirmation}>
                Confirm Anyways
              </button>
              )}

            <div className="text-end fw-bold mt-1 mb-1">
              <Link to='/'>Return to login</Link>
            </div>
            <div>
              New User?{' '}
              <Link to={RoutesEnum.SignUp} className="fw-bold">
                Sign Up!
              </Link>
            </div>
          </div>
        </form>
      </div>
    </LoginContainer>
  )
}
