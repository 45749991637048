import './SignUp.scss'
import React, { type ReactElement, useState } from 'react'
import { type FormikErrors, useFormik } from 'formik'
import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'

import LoginContainer from '../../containers/LoginContainer1'

import Input from '../../components/Input/Input'
import Loader from '../../components/Loader/Loader'
import SvgList from '../../components/Animation/Animation'
import { ReactComponent as Logo1Icon } from '../../icons/logo1.svg'

import mixpanel from '../../utils/mixpanel'
import axiosIct from '../../utils/axios'

import {
  containsCapital,
  containsDigit,
  containsNonCapital,
  containsSpecialCharacter,
  isEmail,
  isPasswordLongEnough,
  isPasswordValid,
  noSpacesOnEnds
} from '../../core/helpers'

import { ReactComponent as RightIcon } from '../../icons/arrow-right.svg'

export interface RegisterInput {
  name: string
  lastname: string
  email: string
  company_name: string
  password: string
  confirm_password: string
}

export default function SignUp (): ReactElement {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik<RegisterInput>({
    initialValues: {
      name: '',
      lastname: '',
      email: '',
      company_name: '',
      password: '',
      confirm_password: ''
    },
    validate: (values) => {
      const errors: FormikErrors<RegisterInput> = {}
      if (values.name === '') {
        errors.name = 'Required'
      }
      if (values.company_name === '') {
        errors.company_name = 'Required'
      }
      if (values.email === '') {
        errors.email = 'Required'
      }
      if (values.email !== '' && !isEmail(values.email)) {
        errors.email = 'Email not valid'
      }
      if (values.password === '') {
        errors.password = 'Required'
      }
      if (values.password !== '' && !isPasswordValid(values.password)) {
        const err = getPasswordError(values.password)
        if (err === 'no whitespace from both ends') {
          errors.password = err
        } else {
          errors.password = 'Should contain ' + err
        }
      }

      // if (values.confirm_password === '') {
      //   errors.confirm_password = 'Required'
      // }

      // if (
      //   values.confirm_password !== '' &&
      //           !isPasswordValid(values.confirm_password)
      // ) {
      //   const err = getPasswordError(values.confirm_password)
      //   if (err === 'no whitespace from both ends') {
      //     errors.confirm_password = err
      //   } else {
      //     errors.confirm_password = 'should contain ' + err
      //   }
      // }

      // if (
      //   values.password !== '' &&
      //           values.confirm_password !== '' &&
      //           isPasswordValid(values.confirm_password) &&
      //           isPasswordValid(values.password) &&
      //           values.password !== values.confirm_password
      // ) {
      //   errors.confirm_password =
      //               'Password and confirm password shloud be equal'
      // }

      return errors
    },
    onSubmit: async (values) => {
      setIsLoading(true)
      const { email = '', password = '', company_name: company = '', name = '', lastname = '' } = values

      const url: string = (process.env.REACT_APP_API_URL ?? '') + '/user/register'
      try {
        await axiosIct.post(url, { firstname: name, lastname: lastname, email, company, password })
        toast.success('Registered Successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark'
        })
        localStorage.setItem('email', email)
        localStorage.setItem('password', password)
        navigate('/confirm-account')
        try {
          mixpanel.track('Sign up')
        } catch (error) {

        }
      } catch (error) {
        console.log(error)
        toast.error('Email Id is already registered', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark'
        })
      } finally {
        setIsLoading(false)
      }
    }
  })

  return (
    <>
    <div className="Header">
      <div className="Header__left h-100">
        <Link onClick={() => { }} to="/">
          <Logo1Icon style={{ height: 50 }} />
        </Link>
      </div>
    </div>
    <div className='signup-anim-container'>

    {isLoading && <Loader />}
    <div className='anim-left d-flex align-items-center justify-content-center'>
    <SvgList />
    </div>
    <LoginContainer>
      <div className='SignUp SignUp__card Login__card px-3 d-flex flex-column align-items-center justify-content-center w-100'>
        <div className='Login__title fw-bold mb-2'>Sign Up</div>
        <div className='mb--80 mb-5'>{'Welcome! Let\'s get started.'}</div>
        <form className='w-100 Login__form ' onSubmit={formik.handleSubmit}>
          <Input
            onBlur={formik.handleBlur}
            // required
            value={formik.values.name}
            onChange={formik.handleChange}
            name='name'
            label='First Name'
            className='mb-4'
            error={(formik.touched.name ?? false) && formik.errors.name}
          />
          <Input
            onBlur={formik.handleBlur}
            // required
            value={formik.values.lastname}
            onChange={formik.handleChange}
            name='lastname'
            label='Last Name'
            className='mb-4'
            error={(formik.touched.lastname ?? false) && (formik.errors.lastname != null)}
          />
          <Input
            onBlur={formik.handleBlur}
            // required
            value={formik.values.email}
            onChange={formik.handleChange}
            name='email'
            label='Email'
            className='mb-4'
            error={(formik.touched.email ?? false) && formik.errors.email}
          />
          <Input
            onBlur={formik.handleBlur}
            // required
            value={formik.values.company_name}
            onChange={formik.handleChange}
            name='company_name'
            label='Company Name'
            className='mb-4'
            error={(formik.touched.company_name ?? false) && formik.errors.company_name}
          />
          <Input
            onBlur={formik.handleBlur}
            // required
            value={formik.values.password}
            onChange={formik.handleChange}
            name='password'
            label='Password'
            className='mb-4'
            type='password'
            error={(formik.touched.password ?? false) && formik.errors.password}
          />
          {/* <Input
            onBlur={formik.handleBlur}
            // required
            value={formik.values.confirm_password}
            onChange={formik.handleChange}
            name='confirm_password'
            label='Confirm Password'
            className='mb-4'
            type='password'
            error={
              (formik.touched.confirm_password ?? false) && formik.errors.confirm_password
            }
          /> */}
          <div className='d-flex flex-column align-items-center justify-content-center mt-5 button-container'>
            <button className='btn btn_blue' type='submit'>
              {'Continue '}
              <RightIcon />
            </button>
            <p className='bottom-line'>Already have an account? <Link to='/'><b>Login</b></Link></p>
          </div>
        </form>
      </div>
    </LoginContainer>
    </div>
    </>
  )
}

export const PASSWORD_VALIDATION_PARAMETERS = [
  'min 8 characters',
  'number',
  'special character',
  'uppercase letter',
  'lowercase letter',
  'no whitespace from both ends'
]

export const passwordValidators: Record<string, (v: string) => boolean> = {
  'min 8 characters': isPasswordLongEnough,
  number: containsDigit,
  'special character': containsSpecialCharacter,
  'uppercase letter': containsCapital,
  'lowercase letter': containsNonCapital,
  'no whitespace from both ends': noSpacesOnEnds
}

const getPasswordError = (value: string): string => {
  return PASSWORD_VALIDATION_PARAMETERS.reduce((acc: string[], item) => {
    if (!passwordValidators[item](value)) {
      acc.push(item)
    }
    return acc
  }, [])[0]
}
