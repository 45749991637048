import React, { type PropsWithChildren, useEffect, useRef } from 'react'
import gsap from 'gsap'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { Transition } from 'react-transition-group'

import { usePrevious } from '../hooks/usePrevious'
import { ReactComponent as Close1Icon } from '../icons/close1.svg'

const duration = 200

export interface ModalProps {
  title?: string
  isOpened: boolean
  closeModal: () => void
  className?: string
}

export default function Modal ({
  title = '',
  isOpened,
  children,
  closeModal
}: PropsWithChildren<ModalProps>): React.ReactElement {
  const ref = useRef<HTMLDivElement>(null)
  const prevIsOpened = usePrevious(isOpened)

  useEffect(() => {
    if (!(prevIsOpened ?? false) && isOpened) {
      gsap.to(ref.current, { duration: 0.35, scale: 1 })
    }

    if ((prevIsOpened ?? false) && !isOpened) {
      gsap.to(ref.current, { duration: 0.35, scale: 0.7 })
    }
  }, [isOpened])

  return createPortal(
    <Transition
      in={isOpened}
      timeout={{ enter: 0, exit: duration }}
      appear
      unmountOnExit
      nodeRef={ref}
    >
      {(tstate: string) => (
        <Overlay tstate={tstate}>
          <div className="container">
            <ModalWrapper ref={ref}>
              <>
                <div className='d-flex justify-content-between flex-row-reverse pb-3 mb-2'>
                  <Button onClick={closeModal} className='p-0 border-0 p-0 bg-transparent cursor-pointer'>
                    <Close1Icon />
                  </Button>
                  <ModalTitle >{title}</ModalTitle>
                </div>

                {children}
              </>
            </ModalWrapper>
          </div>
        </Overlay>
      )}
    </Transition>,
    document.getElementById('modals') as Element
  )
}

const Overlay = styled.div<{ tstate: string }>`
  padding-top: 25px;
  padding-bottom: 25px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(10, 5, 39, 0.3);
  opacity: ${(props) => (props.tstate === 'entered' ? 1 : 0)};
  transition: opacity ${duration}ms ease-in-out;
  z-index: 1020;
  overflow-y: auto;
`

const ModalWrapper = styled.div`
  background: #ffffff;
  padding: 30px 30px 60px;
  width: 100%;
  max-width: 750px;
  border-radius: 20px;
  margin: auto;
  transform: scale(0.8);
`

const Button = styled.button`
  svg {
    width: 30px;
    height: 30px;
    transition: transform 250ms;
  }
  &:hover {
    svg {
      transform: rotate(180deg);
    }
  }
`

const ModalTitle = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: #072136;
  margin-top: 5px;
`
