import React, { useEffect } from 'react'
import Footer from './components/Footer/Footer'
import DashboardContainer from './containers/DashboardContainer'
import { RoutesEnum } from './core/enums'
import { type IRoute } from './core/types'
import { useScrollTopOnRouteChange } from './hooks/useScrollTopOnRouteChange'
// import Login from './pages/Login/Login'
import VerifyMagicLink from './pages/Login/VerifyMagicLink'
import MagicLinkExpired from './pages/Login/MagicLinkExpired'
import LoginMagicLink from './pages/Login/LoginMagicLink'
import Page404 from './pages/Page404'
import ResetPassword from './pages/ResetPassword/ResetPassword'
import SignUp from './pages/SignUp/SignUp'
import Routes from './Routes'
import { useAppSelector } from './hooks/useReduxToolkit'
import SetupPreference from './pages/Preference/SetupPreference'
import ForgotPassword from './pages/ForgotPassword/ForgotPassword'
import ConfirmAccount from './pages/ConfirmAccount/ConfirmAccount'
import RfpDetail from './pages/RfpDetail/RfpDetail'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'

const Page404Route: IRoute = {
  path: '*',
  redirectTo: '/',
  element: <Page404 />
}

const publicRoutes: IRoute[] = [
  {
    path: '/',
    element: <LoginMagicLink />
  },
  {
    path: RoutesEnum.VerifyMagicLink,
    element: <VerifyMagicLink />
  },
  {
    path: RoutesEnum.ResetPassword,
    element: <ResetPassword />
  },
  {
    path: RoutesEnum.MagicLinkSessionExpired,
    element: <MagicLinkExpired />
  },
  {
    path: RoutesEnum.LoginMagicLink,
    element: <LoginMagicLink />
  },
  {
    path: RoutesEnum.ForgotPassword,
    element: <ForgotPassword />
  },
  {
    path: RoutesEnum.SignUp,
    element: <SignUp />
  },
  {
    path: RoutesEnum.ConfirmAccount,
    element: <ConfirmAccount />
  },
  {
    path: '/*',
    element: <LoginMagicLink />
  },
  {
    path: '/',
    redirectTo: '/'
  },
  {
    path: RoutesEnum.RfpDetail,
    element: <RfpDetail />
  }
]

const privateRoutes: IRoute[] = [
  {
    path: RoutesEnum.SetupPreference,
    element: <SetupPreference />
  },
  {
    path: '/*',
    element: <DashboardContainer />
  }
]

export default function AppRoutes (): React.ReactElement {
  const user = useAppSelector((s) => s.user.user)

  const location = useLocation()

  useEffect(() => {
    if (ReactGA.isInitialized) {
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname + location.search
      })
    }
  }, [location])

  useScrollTopOnRouteChange()
  const curRoutes = [
    ...((user.userId.length > 0) ? privateRoutes : publicRoutes),
    Page404Route
  ]

  // if (user.userId.length > 0) {
  const params = new URLSearchParams(window.location.search)

  if (params != null) {
    const redirectUrl = params.get('redirectUrl')
    if (redirectUrl != null) {
      console.log('redirect url is set to', redirectUrl)
      window.location.href = redirectUrl
    }
  }
  // }
  return (
    <div className="min-vh-100 d-flex flex-column justify-content-between">
      <Routes routes={curRoutes} />
      <Footer />
    </div>
  )
}
