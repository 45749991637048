import React, { useState } from 'react'

import Loader from '../../components/Loader/Loader'

export interface BillingInput {
  address: string
  card_number: string
  city: string
  cvv: string
  email: string
  expiration_date: string
  name_on_card: string
  state: string
  street: string
  zip_code: string
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLElement>,
      HTMLElement
      >
    }
  }
}

export default function BillingSetting (): React.ReactElement {
  // const dispatch = useAppDispatch()
  // const billingDetail = useAppSelector((s) => s.settings.billingDetails)
  // const user = useAppSelector((s) => s.user.user)

  const [loading] = useState(false)

  return (
    <>
      {loading && <Loader />}

      <div className="billing-setting">
        {/* <stripe-pricing-table
          pricing-table-id="prctbl_1NE7WXBOOSdBnYBP2OoNGnxJ"
          publishable-key="pk_test_rfSj3EuphA1xgZt2R3DI3CLy"
          client-reference-id={user?.companyId}
        ></stripe-pricing-table> */}
      <p>This is your payment component.</p>
      </div>
    </>
  )
}
