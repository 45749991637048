import { useEffect, useState } from 'react'
import { type RfpListType } from '../core/types'

// Open or create the IndexedDB database
export const dbPromise: Promise<IDBDatabase> = new Promise<IDBDatabase>((resolve, reject) => {
  const request = indexedDB.open('myDatabase', 1)

  request.onerror = (event: Event) => {
    reject(request.error)
  }

  request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
    const db = (event.target as IDBOpenDBRequest).result
    db.createObjectStore('dataStore', { keyPath: 'id' })
  }

  request.onsuccess = (event: Event) => {
    resolve((event.target as IDBOpenDBRequest).result)
  }
})

export const setDataInDB = async (key: string, data: RfpListType): Promise<void> => {
  const db = await dbPromise
  const tx = db.transaction('dataStore', 'readwrite')
  const store = tx.objectStore('dataStore')
  // Serialize the data before storing it

  const serializedData = data.length !== 0 ? JSON.stringify(data) : ''

  store.put({ id: key, data: serializedData })
  await new Promise<void>((resolve) => {
    tx.oncomplete = () => {
      resolve()
    }
  })
}

export const getDataFromDB = async (key: string): Promise<RfpListType | undefined> => {
  const db = await dbPromise
  const tx = db.transaction('dataStore', 'readonly')
  const store = tx.objectStore('dataStore')
  const request = store.get(key)

  return await new Promise<RfpListType | undefined>((resolve: (value?: RfpListType | undefined) => void, reject: (reason?: any) => void) => {
    request.onsuccess = () => {
      const result = (request.result as { data: string })
      // Check if result.data is a non-empty string before parsing
      try {
        resolve(JSON.parse(result.data))
      } catch (error) {
        console.error('Error parsing data:', error)
        resolve([]) // Resolve with undefined if there's an error parsing data
      }
    }
    request.onerror = () => {
      reject(request.error)
    }
  })
}

export const useIndexedDB = (key: string, initialState: RfpListType): [RfpListType, (data: RfpListType) => void] => {
  const [state, setState] = useState<RfpListType>(initialState)

  useEffect(() => {
    const fetchDataFromDB = async (): Promise<void> => {
      try {
        const existingData = await getDataFromDB(key)
        if (existingData !== undefined && existingData !== null) {
          setState(existingData)
        }
      } catch (error) {
        console.error('Error retrieving data from IndexedDB:', error)
      }
    }
    void fetchDataFromDB()
  }, [key]) // Dependency on key to trigger the effect when key changes

  const setData = async (newData: RfpListType): Promise<void> => {
    await setDataInDB(key, newData)
    setState(newData)
  }

  console.log(state)

  return [state, setData]
}
