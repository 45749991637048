import React, { type PropsWithChildren } from 'react'
import { ReactComponent as Logo2Icon } from '../icons/logo2.svg'

export default function LoginContainer ({ children }: PropsWithChildren): React.ReactElement {
  return (
    <div className="bg-theme flex-grow-1">
      <div className='container Login flex-grow-1 d-flex align-items-center flex-column'>
        <Logo2Icon />
        {children}
      </div>
    </div>
  )
}
