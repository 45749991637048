import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import Card from '../../components/Card/Card'
import axiosIct from '../../utils/axios'

interface INewsDetail {
  content: Array<{
    name: string
    rfps: Array<{
      id: string
      title: string
      due_date: string
      slug: string
    }>
  }>
  created_at: number
  id: string
  end_date: string
  publish_date: string
  slug: string
  start_date: string
  type: string
  type_value: string
}

export default function NewsDetail (): React.ReactElement {
  const { slug = '', type = '' } = useParams<{ slug: string, type: string }>()
  const [newsData, setNewsData] = useState<null | INewsDetail>(null)

  useEffect(() => {
    axiosIct.get(`/data/news/${type}/${slug}`).then((r: any) => {
      setNewsData(r.data)
    }).catch(e => {
    })
  }, [])

  return (
    <div className='container py-5'>
      {
        newsData !== null &&
        <Card title={`${newsData?.type_value} RFPs - Week of ${newsData?.publish_date}`}>
          <p>
            RFPs and US Government bid opportunities for {newsData?.type_value} for the week of {newsData?.start_date} to {newsData?.end_date}
          </p>
          {
            newsData.content.map(c => <>
              <h3>{c.name}</h3>
              <p> The Weekly RFP Roundup of {c.name} </p>
              <ul>
                {c.rfps.map(rfp =>
                  <li className='py-2' key={rfp.id}>{rfp.title}</li>
                )}
              </ul>
            </>)
          }
          <p>
            To get the best RFPs for you sign up for a free trial of One Click RFP <a href="/">here</a>.
          </p>
        </Card>
      }
    </div>
  )
}
