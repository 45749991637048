import './RatingBadge.scss'
import React from 'react'

import { BADGE_COLORS } from '../../core/data'

export interface PROPS_TYPES {
  letter: string
}

export default function RatingBadge ({ letter }: PROPS_TYPES): React.ReactElement {
  const badge = BADGE_COLORS.find((badge) => badge.name === letter)
  const style = {
    borderColor: badge?.color,
    color: badge?.color
  }
  return (
    <span className="RatingBadge" style={style}>
      {letter}
    </span>
  )
}
