import React, { useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import './App.scss'
import AppRoutes from './AppRoutes'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import mixpanel from './utils/mixpanel'

function App (): React.ReactElement {
  useEffect(() => {
    if (typeof process.env.REACT_APP_CLARITY === 'string' && process.env.REACT_APP_CLARITY.length !== 0) {
      // Microsoft clarity code
      const scriptElement = document.createElement('script')
      scriptElement.innerHTML = `
      (function (c, l, a, r, i, t, y) {
        c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
        t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
        y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
      })(window, document, "clarity", "script", "${process.env.REACT_APP_CLARITY}");
      `
      document.head.appendChild(scriptElement)
    }
    try {
      mixpanel.track('Login')
    } catch (error) {

    }
  }, [])
  return (
    <Router>
      <AppRoutes />
      <ToastContainer />
    </Router>
  )
}

export default App
