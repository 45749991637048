import './ResetPassword.scss'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { type FormikErrors, useFormik } from 'formik'

import LoginContainer from '../../containers/LoginContainer'

import Input from '../../components/Input/Input'

import { RoutesEnum } from '../../core/enums'
import axiosIct from '../../utils/axios'

interface FormikValues {
  password: string
  confirm_password: string
}

export default function ResetPassword (): React.ReactElement {
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      password: '',
      confirm_password: ''
    },
    validate: (values) => {
      const errors: FormikErrors<FormikValues> = {}
      if (values.confirm_password.length === 0) {
        errors.confirm_password = 'Required'
      }
      if (values.password.length === 0) {
        errors.password = 'Required'
      }
      if (values.confirm_password !== values.password) {
        errors.confirm_password = 'Password does not match'
      }
      return errors
    },
    onSubmit: (values) => {
      const payload = {
        password: values.password,
        session: localStorage.getItem('session'),
        email: localStorage.getItem('email')
      }
      void axiosIct.post('/user/reset-password', payload).then(() => {
        navigate(RoutesEnum.Login)
      })
    }
  })

  return (
    <LoginContainer>
      <div className='ResetPassword_card Login__card px-3 d-flex flex-column align-items-center justify-content-center w-100'>
        <div className='Login__title fw-bold mb-2'>Reset Password</div>
        {/* <div className="mb-4 Login__subtitle">Reset Your Password</div> */}
        <form className='w-100 Login__form' onSubmit={formik.handleSubmit}>
          <div className='d-flex'>
            <Input
              onBlur={formik.handleBlur}
              value={formik.values.password}
              onChange={formik.handleChange}
              name='password'
              label='New Password'
              className='mb-4 flex-grow-1'
              type='password'
              required
              error={formik.touched.password}
            />
          </div>
          <Input
            onBlur={formik.handleBlur}
            value={formik.values.confirm_password}
            onChange={formik.handleChange}
            name='confirm_password'
            label='Confirm New Password'
            className='mb-5'
            type='password'
            required
            error={formik.touched.confirm_password}
          />
          <div className='d-flex flex-column align-items-center justify-content-center button-container'>
            <button className='btn w-100 Login__btn ' type='submit'>
              Reset
            </button>
            <div className="text-end fw-bold mt-1 mb-1">
              <Link to='/'>Return to login</Link>
            </div>
            <div>
              New User?{' '}
              <Link to={RoutesEnum.SignUp} className="fw-bold">
                Sign Up!
              </Link>
            </div>
          </div>
        </form>
      </div>
    </LoginContainer>
  )
}
