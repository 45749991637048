import './Footer.scss'
import React, { useState } from 'react'
import { toast } from 'react-toastify'

import axiosIct from '../../utils/axios'

import { ReactComponent as Logo3Icon } from '../../icons/logo3.svg'

export default function Footer (): React.ReactElement {
  const [email, setEmail] = useState('')

  // Define your Axios request configuration
  const axiosConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    try {
      const formData = new URLSearchParams()

      formData.append('userGroup', 'News subscriber')
      formData.append('email', email)

      console.log(formData)
      axiosIct.post(`${process.env.REACT_APP_API_URL ?? ''}/webhook/loops/forms`, formData, axiosConfig)
        .then(() => {
          toast.success('Thank you. We will be in touch soon.', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark'
          })
        })
        .catch((err) => {
          console.log(err)
          toast.error('Oops! Something went wrong, please try again', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark'
          })
        })
    } catch (error) {
      console.error('Error submitting form:', error)
    }
  }

  return (
    <div className='Footer text-center text-lg-end'>
      <div className='Footer__left'>
        <Logo3Icon />
        <a href="mailto:support@oneclickrfp.com" target='blank'>support@oneclickrfp.com</a>
        <div className='Footer__term'><a onClick={() => window.open('https://oneclickrfp.com/terms', '_blank')}>{'Terms'}</a> & <a onClick={() => window.open('https://oneclickrfp.com/privacy', '_blank')}>{'Privacy Policy'}</a></div>
      </div>
      <div className='Footer__right'>
        <div className='Footer__text'>Sign up for the latest from One-Click RFP</div>
        <form onSubmit={(e) => { handleSubmit(e) }} className="subscribe-form w-100">
          <label className='subscribe-label text-white d-flex justify-content-start'>Email Address</label>
          <div className="form-group position-relative d-flex w-100">
            <input
              type="email"
              className="form-control w-100 border border-white-4 px-2 bg-transparent py-2 rounded text-white"
              id="emailInput"
              name='emailInput'
              aria-describedby="emailHelp"
              placeholder=""
              value={email}
              onChange={(e) => { setEmail(e.target.value) }}
              required
            />
            <button type="submit" className="position-absolute end-0 bg-transparent btn-subscribe py-2 px-3 text-white">Subscribe &gt;</button>
          </div>
        </form>

      </div>
    </div>
  )
}
