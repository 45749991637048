import './DashboardContainer.scss'
import React from 'react'
import { useAppSelector } from '../hooks/useReduxToolkit'

import { RoutesEnum } from '../core/enums'
import { type IRoute } from '../core/types'
import Routes from '../Routes'

import Header from '../components/Header/Header'

import Dashboard from '../pages/Dashboard/Dashboard'
import DashboardNR from '../pages/Dashboard/DashboardNR'
import PrivacyPolicy from '../pages/Other/PrivacyPolicy'
import TermsConditions from '../pages/Other/TermsConditions'
import RfpDetail from '../pages/RfpDetail/RfpDetail'
import Settings from '../pages/Settings/Settings'
import NewsDetail from '../pages/News/NewsDetail'
import NewsList from '../pages/News/NewsList'

const routes: IRoute[] = [
  {
    path: RoutesEnum.RfpDetail,
    element: <RfpDetail />
  },
  {
    path: RoutesEnum.TermsConditions,
    element: <TermsConditions />
  },
  {
    path: RoutesEnum.PrivacyPolicy,
    element: <PrivacyPolicy />
  },
  {
    path: RoutesEnum.Settings,
    element: <Settings />
  },
  {
    path: RoutesEnum.NewsList,
    element: <NewsList />
  },
  {
    path: RoutesEnum.NewsDetail,
    element: <NewsDetail />
  }
]

const dashRoutes: IRoute[] = [
  {
    path: '/',
    element: <Dashboard />
  },
  {
    path: '*',
    redirectTo: '/',
    element: <Dashboard />
  }
]

const dashNRRoutes: IRoute[] = [
  {
    path: '/',
    element: <DashboardNR />
  },
  {
    path: '*',
    redirectTo: '/',
    element: <DashboardNR />
  }
]

export default function DashboardContainer (): React.ReactElement {
  const userData = useAppSelector(s => s.user.user)
  let subState = null
  let bannerText = ''
  let buttonText = 'Billing'
  let finalRoutes = []
  let billingUrl = ''
  subState = userData.subscriptionStatus
  if (subState === 'trialing') {
    buttonText = 'Upgrade'
    if (userData?.trialDaysLeft != null) {
      bannerText = 'Your free trial will end in ' + String(userData?.trialDaysLeft) + ' days. To continue using One Click, upgrade here.'
    } else {
      bannerText = 'Your are on your trial period.'
    }
  } else if (subState === 'notvalid') {
    bannerText = 'Upgrade to use OneClick RFP features and more.'
    buttonText = 'Start Free Trial'
  } else if (subState !== 'active') {
    buttonText = 'Reactivate'
    bannerText = 'Your subscription has been deactivated. Reactivate your subscription here'
  }

  return (
    <div className="flex-grow-1 DashboardContainer d-flex flex-column">
    {(() => {
      switch (subState) {
        case 'trialing':
          finalRoutes = routes.concat(dashRoutes)
          billingUrl = process.env.REACT_APP_STRIPE_BILLING_URL !== undefined ? process.env.REACT_APP_STRIPE_BILLING_URL : billingUrl
          return <Header bannerText={bannerText} bannerVisible={true} bannerButtonText={buttonText} billingUrl={billingUrl}/>
        case 'active':
          finalRoutes = routes.concat(dashRoutes)
          return <Header bannerText="" bannerVisible={false} />
        default:
          finalRoutes = routes.concat(dashNRRoutes)
          billingUrl = process.env.REACT_APP_STRIPE_BILLING_URL !== undefined ? process.env.REACT_APP_STRIPE_BILLING_URL : billingUrl
          return <Header bannerText={bannerText} bannerVisible={true} bannerButtonText={buttonText} billingUrl={billingUrl}/>
      }
    })()}
      <div className="flex-grow-1 d-flex flex-column">
        <Routes routes={finalRoutes} />
      </div>
    </div>
  )
}
