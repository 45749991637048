import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axiosIct from '../../../utils/axios'
interface DataSlice {
  rfpSources: Array<{ label: string, value: string }>
  placeOfPerformance: Array<{ label: string, value: string, isDefault: boolean }>
  fetched: boolean
}

const initialState: DataSlice = {
  rfpSources: [],
  placeOfPerformance: [],
  fetched: false
}

export const fetchFilterItems = createAsyncThunk(
  'dataSlice/fetchFilterItems',
  async () => {
    return await axiosIct.get('/data/filters').then((r) => r.data)
  }
)

const dataslice = createSlice({
  name: 'dataSlice',
  initialState,
  reducers: {
    setFetched: (state, action) => {
      state.fetched = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFilterItems.fulfilled, (state, action) => {
      state.placeOfPerformance = action.payload.pop
      state.rfpSources = action.payload.source
      state.fetched = true
    })
  }
})

export default dataslice.reducer
export const { setFetched } = dataslice.actions
