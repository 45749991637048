import axios, { type AxiosInstance } from 'axios'

const axiosIct: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL ?? ''
  // baseURL: 'https://api-stage.oneclickrfp.com/api'
})

axiosIct.interceptors.request.use((config) => {
  const token = localStorage.getItem('id_token')
  if (token !== null) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}, async (error) => {
  return await Promise.reject(error)
})

axiosIct.interceptors.response.use(response => response, async error => {
  if (error.response.status === 401) {
    localStorage.clear()
    window.location.href = '/'
  }
  return await Promise.reject(error)
})

export default axiosIct
