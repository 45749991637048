import './News.scss'

import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import axiosIct from '../../utils/axios'

interface INewsDetail {
  content: Array<{
    name: string
    rfps: Array<{
      id: string
      title: string
      due_date: string
      slug: string
    }>
  }>
  created_at: number
  id: string
  end_date: string
  publish_date: string
  slug: string
  start_date: string
  type: string
  type_value: string
}

export default function NewsList (): React.ReactElement {
  const [stateData, setStateData] = useState<INewsDetail[]>([])
  const [naicsData, setNaicsData] = useState<INewsDetail[]>([])

  useEffect(() => {
    axiosIct.get('/data/news/state').then((r: any) => {
      setStateData(r.data.data)
    }).catch(e => {
    })
    axiosIct.get('/data/news/naics').then((r: any) => {
      setNaicsData(r.data.data)
    }).catch(e => {
    })
  }, [])

  return (
    <>
      <div className="container news-list-container py-4">
        <div className='row'>
          <div className="col-lg-6 my-3">
            <div className='list-card p-4'>
              <h1 className="list-card__title">State's Weekly RFPs</h1>
              <ul>
                {
                  stateData.map(news =>
                    <li key={news?.slug} className='py-1'>
                      <Link to={`/news/${news.type}/${news?.slug}`} style={{ textDecoration: 'none' }}>
                        {news?.publish_date} - State of {news?.type_value}
                      </Link>
                    </li>
                  )
                }
              </ul>
            </div>
          </div>
          <div className="col-lg-6 my-3">
            <div className='list-card p-4'>
              <h1 className="list-card__title">NAICS's Weekly RFPs</h1>
              <ul>
                {
                  naicsData.map(news =>
                    <li key={news?.slug} className='py-1'>
                      <Link to={`/news/${news.type}/${news?.slug}`} style={{ textDecoration: 'none' }}>
                        {news?.publish_date} - NAICS {news?.type_value}
                      </Link>
                    </li>
                  )
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
