import './preferences.scss'
import React, { useState, useEffect } from 'react'
import { produce } from 'immer'
import { toast } from 'react-toastify'
// import { useNavigate } from 'react-router-dom'
// import _ from 'lodash'
import { isEqual } from 'lodash'

import ModifiedMultiSelect from './preference'
import Modal from '../../components/Modal'
import Loader from '../../components/Loader/Loader'

import { SET_ASIDES_DATA, NAICS_CODES_DATA } from '../../core/data'
import { useAppDispatch, useAppSelector } from '../../hooks/useReduxToolkit'
import { fetchUserDetails, updateDefaultFilters } from '../../core/store/user/userSlice'
import { fetchFilterItems, setFetched } from '../../core/store/data/dataSlice'

import axiosIct from '../../utils/axios'
import { useModal } from '../../hooks/useModal'

import { ReactComponent as RightIcon } from '../../icons/arrow-right-blue.svg'

export default function Preferences (): React.ReactElement {
  // const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const length = 3
  const rfpSources = useAppSelector((s) => s.data.rfpSources)
  const filterItemsFetched = useAppSelector(s => s.data.fetched)
  const defaultFilters = useAppSelector(s => s.user.user?.defaultFilter)
  const placeOfPerformance = useAppSelector(s => s.data.placeOfPerformance)

  const [modalTitle] = useState('Request new source')
  const [index, setIndex] = useState(0)

  const [save, setSave] = useState(false)
  const [requestSourceName, setRequestName] = useState('')
  const [loading, setLoading] = useState(false)

  const {
    isModalOpened: isUserModalOpened,
    closeModal: closeUserModal,
    openModal: openUserModal
  } = useModal()

  const [allFilters, setAllFilters] = useState([{
    subtitle: 'Place Of Performance',
    value: defaultFilters.place_of_performance,
    showAll: false,
    options: placeOfPerformance
  },
  {
    subtitle: 'NAICS Code',
    value: defaultFilters.naics_code,
    showAll: false,
    options: NAICS_CODES_DATA.map(nc => ({ ...nc, label: `${nc.value} - ${nc.label}` }))
  },
  {
    subtitle: 'Set-Aside',
    value: defaultFilters.set_aside,
    showAll: false,
    options: SET_ASIDES_DATA
  },
  {
    subtitle: 'RFP Sources',
    value: defaultFilters.rfp_source,
    showAll: false,
    options: rfpSources
  }
  ])

  const cancelSave = (): void => {
    setAllFilters([{
      subtitle: 'Place Of Performance',
      value: defaultFilters.place_of_performance,
      showAll: false,
      options: placeOfPerformance
    },
    {
      subtitle: 'NAICS Code',
      value: defaultFilters.naics_code,
      showAll: false,
      options: NAICS_CODES_DATA.map(nc => ({ ...nc, label: `${nc.value} - ${nc.label}` }))
    },
    {
      subtitle: 'Set-Aside',
      value: defaultFilters.set_aside,
      showAll: false,
      options: SET_ASIDES_DATA
    },
    {
      subtitle: 'RFP Sources',
      value: defaultFilters.rfp_source,
      showAll: false,
      options: rfpSources
    }
    ])
  }

  useEffect(() => {
    if (!filterItemsFetched) {
      setLoading(true)
      dispatch(fetchFilterItems()).finally(() => { setLoading(false) })
    }
  }, [filterItemsFetched])

  // useEffect for dispatching fresh filters detail
  useEffect(() => {
    setAllFilters(() => {
      return produce(allFilters, draft => {
        draft[0].value = defaultFilters.place_of_performance
        draft[1].value = defaultFilters.naics_code
        draft[2].value = defaultFilters.set_aside
        draft[3].value = defaultFilters.rfp_source
      })
    })
  }, [defaultFilters])

  // useEffect for update filter after render
  useEffect(() => {
    const pop = [...placeOfPerformance]
    const index = pop.findIndex((element) => element.label === 'Sam.gov')
    if (index > -1) {
      const sam = pop.splice(index, 1)[0]
      pop.unshift(sam)
    }

    setAllFilters(() => {
      return produce(allFilters, draft => {
        draft[0].options = pop
        draft[3].options = rfpSources
      })
    })
  }, [rfpSources, placeOfPerformance])

  // source request handling
  const handleRequestSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    setLoading(true)
    axiosIct.post('/user/source_request', { request: requestSourceName }).then(() => {
      toast.success('Your Request for source has been submitted!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      })
      setRequestName('')
      closeUserModal()
    }).catch(() => {
      toast.error('Something went wrong!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      })
    }).finally(() => {
      setLoading(false)
    })

    e.preventDefault()
  }

  // useEffect for saving changes
  useEffect(() => {
    if (save) {
      setLoading(true)
      const options = {
        place_of_performance: allFilters[0].value,
        naics_code: allFilters[1].value,
        set_aside: allFilters[2].value,
        rfp_source: allFilters[3].value
      }
      void dispatch(updateDefaultFilters(options)).then(() => {
      }).then(async () => await dispatch(fetchUserDetails())).then(() => dispatch(setFetched(false))).then(() => { }).then(() => { setLoading(false) })
    }

    setSave(false)
  }, [save])

  const onFilterChange = (index: number, values: any): void => {
    if (index === 0 && values.length === 0) {
      values = ['Federal']
      toast.error('At least 1 place of performance should be selected.', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      })
    }
    setAllFilters(() => {
      return produce(allFilters, draft => {
        draft[index].value = values
      })
    })
  }

  return (
    <>
      {loading && <Loader />}

      <div className='Setup1'>
        <div className='Setup1__navigate'>
          <a className={index === 0 ? 'Setup__active' : 'Setup__inactive'} onClick={() => { setIndex(0) }}> NAICS Code </a> <RightIcon />
          <a className={index === 1 ? 'Setup__active' : 'Setup__inactive'} onClick={() => { setIndex(1) }}> Place of Performance </a> <RightIcon />
          <a className={index === 2 ? 'Setup__active' : 'Setup__inactive'} onClick={() => { setIndex(2) }}> Set Aside </a>
        </div>

        <div className='Setup1__line' />

        {index === 0 && (
          <div className="rfp-settings-edit-info-category1">
            <ModifiedMultiSelect
              titleSubHeading='Tell us the work your’re interested in. Feel free to be as specific or general as you’d like. To enter a new NAICS code, press ’Enter’'
              value={allFilters[1].value}
              currentIndex={index + 1}
              isSelectAllOption={true}
              clearAllSelectOption={true}
              length={length}
              title={allFilters[1].subtitle}
              key={allFilters[1].subtitle}
              options={allFilters[1].options}
              onChange={(values) => { onFilterChange(1, values) }}
            />
          </div>
        )
        }
        {index === 1 &&
          (<div className="rfp-settings-edit-info-category1">
            <ModifiedMultiSelect
              titleSubHeading='When you select a state, we will include any municipalities in that state where we are currently collecting data.'
              value={allFilters[0].value}
              currentIndex={index + 1}
              isSelectAllOption={true}
              clearAllSelectOption={true}
              length={length}
              title={allFilters[0].subtitle}
              key={allFilters[0].subtitle}
              options={allFilters[0].options}
              onChange={(values) => { onFilterChange(0, values) }}
            />
          </div>
          )
        }
        {
          index === 2 &&
          (<div className="rfp-settings-edit-info-category1">
            <ModifiedMultiSelect
              titleSubHeading='We’ll include any set-asides you qualify for.'
              selectAtLeastOne={true}
              value={allFilters[2].value}
              isSelectAllOption={true}
              key={allFilters[2].subtitle}
              openUserModal={openUserModal}
              currentIndex={index + 1}
              clearAllSelectOption={true}
              length={length}
              title={allFilters[2].subtitle}
              options={allFilters[2].options}
              onChange={(values) => { onFilterChange(2, values) }}
              requestSource={true}
            />
          </div>
          )
        }
        {index === 0 && (
          <div className="button-container">
            <button disabled={isEqual(allFilters[1]?.value, defaultFilters?.naics_code)} onClick={() => {
              setSave(true)
              setIndex(1)
            }} className='btn btn-lg continue-button' >
              Save Changes
            </button>
            {!isEqual(allFilters[1]?.value, defaultFilters?.naics_code) && (
              <button onClick={() => { cancelSave() }} className="btn btn-lg mx-2  back-button">
                <span>Cancel</span>
              </button>
            )}
          </div>
        )}
        {index === 1 && (
          <div className="button-container">
            <button disabled={isEqual(allFilters[0]?.value, defaultFilters?.place_of_performance)} onClick={() => {
              setSave(true)
              setIndex(2)
            }} className='btn btn-lg continue-button' >
              Save Changes
            </button>
            {!isEqual(allFilters[0]?.value, defaultFilters?.place_of_performance) && (
              <button onClick={() => { cancelSave() }} className="btn btn-lg mx-2  back-button">
                <span>Cancel</span>
              </button>
            )}
          </div>
        )}
        {index === 2 && (
          <div className="button-container">
            <button disabled={isEqual(allFilters[2]?.value, defaultFilters?.set_aside)} onClick={() => { setSave(true) }} className='btn btn-lg continue-button' >
              Save Changes
            </button>
            {!isEqual(allFilters[2]?.value, defaultFilters?.set_aside) && (
              <button onClick={() => { cancelSave() }} className="btn btn-lg mx-2  back-button">
                <span>Cancel</span>
              </button>
            )}
          </div>
        )}
      </div>

      <Modal isOpened={isUserModalOpened} closeModal={closeUserModal} title={modalTitle} >
        <h2 className="modal__applied__title">Request new source</h2>
        <div className="modal__applied__text">
          Letting us know will mark this RFP as “Applied” and <br />
          improve our ability to find your Right-Fit RFP.
        </div>
        <form onSubmit={handleRequestSubmit} style={{ width: '90%', margin: 'auto' }} >
          <p className="fw-bold mb-2">Source URL</p>
          <input onChange={(e) => { setRequestName(e.target.value) }} value={requestSourceName} className='w-100 p-3 sourceInput1'
          />
          <div className="modal__applied__buttons  ">
            <button type="submit" className="btn request-button1">Send Request</button>
          </div>
        </form>
      </Modal>
    </>
  )
}
