import './Dashboard.scss'

import React, { useEffect, useState } from 'react'
import { fetchUserDetails } from '../../core/store/user/userSlice'
import { useAppDispatch, useAppSelector } from '../../hooks/useReduxToolkit'

export default function DashboardNR (): React.ReactElement {
  const dispatch = useAppDispatch()
  const userData = useAppSelector(s => s.user.user)
  const [stripeLinkOpened, setStripeLinkOpened] = useState(true)

  useEffect(() => {
    const doAsync = async (): Promise<void> => {
      await dispatch(fetchUserDetails())
      // setLoading(false)
    }

    const intervalId = setInterval(() => {
      fetchTrialStatusAndUpdate().catch(error => {
        console.error('Error in fetchTrialStatusAndUpdate:', error)
      })
    }, 10000)

    const fetchTrialStatusAndUpdate = async (): Promise<void> => {
      try {
        if (userData.subscriptionStatus !== 'active' && userData.subscriptionStatus !== 'trialing') {
          await doAsync()
        } else {
          clearInterval(intervalId) // Clear interval if subscription status is not 'inactive', 'cancelled', or 'notvalid'
        }
      } catch (error) {
        console.error('Error in fetchTrialStatusAndUpdate:', error)
      }
    }

    if (!stripeLinkOpened) {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      window.open(`${process.env.REACT_APP_STRIPE_URL}?prefilled_email=${userData.email}`)
      setStripeLinkOpened(true)
    }
    // Clean up event listener and interval when the component unmounts
    return () => {
      clearInterval(intervalId)
    }
  }, [])

  return (
    <>
      <div className="container dashboard py-5">
        <div className="d-lg-flex">
          <div className="dashboard__content">
                  <div className='dashboard_no_result'>
                    No Results
                  </div>
          </div>
      </div>
      </div>
   </>
  )
}
