import './Checkbox.scss'
import cn from 'classnames'
import React, { type ChangeEventHandler, useEffect, useRef } from 'react'

import { ReactComponent as TipIcon } from '../../icons/tip_white.svg'
import { ReactComponent as MinusIcon } from '../../icons/minus.svg'

export enum CheckboxEnum {
  Checkbox,
  Radio,
}

interface Props {
  id?: string
  name?: string
  checked: boolean
  indeterminate?: boolean
  disabled?: boolean
  type?: CheckboxEnum
  onChange: ChangeEventHandler<HTMLInputElement>
  className?: string
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  labelColor?: string
  isError?: boolean
  isBadge?: boolean
  labelId?: string
  label: JSX.Element | string
  theme?: '' | 'primary'
  onlyLabel?: boolean
  fade?: boolean
}

const Checkbox = ({
  type = CheckboxEnum.Checkbox,
  checked,
  onChange,
  onBlur,
  className = '',
  labelColor,
  isError = false,
  isBadge = false,
  disabled = false,
  indeterminate = false,
  id,
  name = '',
  label,
  labelId = '',
  theme = '',
  onlyLabel = false,
  fade = false
}: Props): React.ReactElement => {
  const isCheckbox = type === CheckboxEnum.Checkbox

  const cRef = useRef<any>()

  useEffect(() => {
    if (cRef.current !== undefined) {
      setTimeout(() => {
        cRef.current.indeterminate = indeterminate
      }, 1000)
    }
  }, [cRef, indeterminate])

  const labelStyles = {
    ...(labelColor !== undefined
      ? {
          color: labelColor,
          borderColor: labelColor
        }
      : {})
  }

  return (
    <div className={`Checkbox ${className} ${theme}`}>
      <label
        className={cn('d-flex align-items-center Checkbox__container', {
          Checkbox__error: isError
        })}
        data-testid={name}
      >

      <input
      id={id}
      name={name}
      type={isCheckbox ? 'checkbox' : 'radio'}
      onChange={onChange}
      checked={checked || indeterminate}
      disabled={disabled}
      onBlur={onBlur}
      data-testid={'checkbox_' + name}
    />

        {!onlyLabel &&
          <div
          className={cn('Checkbox__input', {
            'Checkbox__input--radio': !isCheckbox,
            'Checkbox__input--checkbox': isCheckbox
          })}
        >
          {isCheckbox && (
            indeterminate ? <MinusIcon className='icon-indeterminate' /> : <TipIcon />
          )}
        </div>

      }
      <div
        className={cn('Checkbox__label', {
          'opacity-50': fade ? !checked : false,
          label__badge: isBadge
        })}

          style={labelStyles}
          id={labelId}
        >
          {label}
        </div>
      </label>
    </div>
  )
}

export default Checkbox
