import { configureStore } from '@reduxjs/toolkit'

import userReducer from './user/userSlice'
import dashboardReducer from './dashboard/dashboardSlice'
import settingReducer from './setting/settingslice'
import dataSliceReducer from './data/dataSlice'

const rootReducer = {
  user: userReducer,
  dashboard: dashboardReducer,
  settings: settingReducer,
  data: dataSliceReducer
}

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV === 'development'
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store
