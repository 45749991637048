import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import axiosIct from '../../../utils/axios'

type UserList = Array<{
  company_id: string
  creation_date: number
  email: string
  is_admin: boolean
  name: string
  user_id: string
  status: string
}>

interface BillingDetail {
  address: string
  card_number: string
  city: string
  cvv: string
  email: string
  expiration_date: string
  name_on_card: string
  state: string
  street: string
  zip_code: string
}

interface settingsSlice {
  userList: UserList
  billingDetails: BillingDetail
  settingTab: number
}

const initialState: settingsSlice = {
  userList: [],
  billingDetails: {

    address: '',
    card_number: '',
    city: '',
    cvv: '',
    email: '',
    expiration_date: '',
    name_on_card: '',
    state: '',
    street: '',
    zip_code: ''

  },
  settingTab: 0
}

export const fetchUserList = createAsyncThunk('setting/fetchUserList', async () => {
  return await axiosIct.get('/user/list').then((r) => r.data)
})

export const addUser = createAsyncThunk('setting/adduser', async (options: any, { dispatch }) => {
  await axiosIct.post('/user/create', options).then((r) => r.data).then(() => { void dispatch(fetchUserList()) }).catch((err) => {
    console.log(err.response.data.error)
    toast.error(err.response.data.error, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark'
    })
  })
})

export const deleteUser = createAsyncThunk('setting/deleteUser', async (options: any, { dispatch }) => {
  return await axiosIct.post('/user/delete', options).then((r) => r.data).then(() => { void dispatch(fetchUserList()) })
})

export const updateUser = createAsyncThunk('setting/updateuser', async (options: any, { dispatch }) => {
  return await axiosIct.put('/user/update', options).then((r) => r.data).then(() => { void dispatch(fetchUserList()) })
})

export const fetchBillingDetails = createAsyncThunk('setting/fetchBillingDetails', async () => {
  return await axiosIct.get('/user/billing').then((r) => r.data)
})

export const updateBillingDetail = createAsyncThunk('setting/updateBillingDetail', async (options: any, { dispatch }) => {
  return await axiosIct.put('/user/billing', options).then((r) => r.data).then(() => {
    void dispatch(fetchBillingDetails())
  })
})

const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    resetSettingSlice: (state) => {
      state = {
        userList: [],
        billingDetails: {

          address: '',
          card_number: '',
          city: '',
          cvv: '',
          email: '',
          expiration_date: '',
          name_on_card: '',
          state: '',
          street: '',
          zip_code: ''

        },
        settingTab: 0

      }
    },
    setSettingTab: (state, action) => {
      state.settingTab = action.payload
    }
  },
  extraReducers (builder) {
    builder.addCase(fetchUserList.fulfilled, (state, action) => {
      state.userList = action.payload.users
    })
    builder.addCase(fetchUserList.rejected, (state, action) => {
    })
    builder.addCase(addUser.fulfilled, (state, action) => {
      // alert("User Created");

    })
    builder.addCase(fetchBillingDetails.fulfilled, (state, action) => {
      state.billingDetails = action.payload
    })
    builder.addCase(updateBillingDetail.fulfilled, (state, action) => {
      // alert("Billing Information Updated Successfully!");
    })
  }
})

export default settingSlice.reducer
export const { setSettingTab } = settingSlice.actions
