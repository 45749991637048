const BADGE_COLORS = [
  {
    color: '#76C791',
    name: 'A'
  },
  {
    color: '#76C791',
    name: 'B'
  },
  {
    color: '#5EB5CA',
    name: 'C'
  },
  {
    color: '#F37845',
    name: 'D'
  }
]

const NAICS_CODES = [
  {
    naics_code: '11',
    definition: 'Agriculture, Forestry, Fishing and Hunting'
  },
  {
    naics_code: '21',
    definition: 'Mining, Quarrying, and Oil and Gas Extraction'
  },
  {
    naics_code: '22',
    definition: 'Utilities'
  },
  {
    naics_code: '23',
    definition: 'Construction'
  },
  {
    naics_code: '31-33',
    definition: 'Manufacturing'
  },
  {
    naics_code: '42',
    definition: 'Wholesale Trade'
  },
  {
    naics_code: '44-45',
    definition: 'Retail Trade'
  },
  {
    naics_code: '48-49',
    definition: 'Transportation and Warehousing'
  },
  {
    naics_code: '51',
    definition: 'Information'
  },
  {
    naics_code: '52',
    definition: 'Finance and Insurance'
  },
  {
    naics_code: '53',
    definition: 'Real Estate and Rental and Leasing'
  },
  {
    naics_code: '54',
    definition: 'Professional, Scientific, and Technical Services'
  },
  {
    naics_code: '55',
    definition: 'Management of Companies and Enterprises'
  },
  {
    naics_code: '56',
    definition:
      'Administrative and Support and Waste Management and Remediation Services'
  },
  {
    naics_code: '61',
    definition: 'Educational Services'
  },
  {
    naics_code: '62',
    definition: 'Health Care and Social Assistance'
  },
  {
    naics_code: '71',
    definition: 'Arts, Entertainment, and Recreation'
  },
  {
    naics_code: '72',
    definition: 'Accommodation and Food Services'
  },
  {
    naics_code: '81',
    definition: 'Other Services (except Public Administration)'
  },
  {
    naics_code: '92',
    definition: 'Public Administration'
  }
]

const SET_ASIDES = [
  '8(a) Business Development Program',

  'HUBZone Program',

  'Service-Disabled Veteran-Owned Small Business Program',

  'Women-Owned Small Business Program',

  'Economically Disadvantaged Women-Owned Small Business Program',

  'Small Business',

  'Small Disadvantaged Business',

  'Historically Underutilized Business Zone (HUBZone)',

  'Veteran-Owned Small Business',

  'Service-Disabled Veteran-Owned Small Business',

  'Women-Owned Small Business',

  'Economically Disadvantaged Women-Owned Small Business',

  'Indian-Owned Small Business',

  'Native Hawaiian-Owned Small Business',

  'Alaska Native-Owned Small Business',

  'Tribally-Owned Small Business',

  'Minority-Owned Business',

  'Black-Owned Business',

  'Hispanic-Owned Business',

  'Asian-Pacific-Owned Business',

  'Subcontinent Asian-Owned Business',

  'Other Than Small Business'
]

const SET_ASIDES_DATA = [
  {
    label: 'Small Business Set-Asides',
    value: 'SBA'
  },
  {
    label: '8(a) Business Development Program',
    value: '8A'
  },
  {
    label: 'HUBZone Program',
    value: 'HZC'
  },
  {
    label: 'Women-Owned Small Business (WOSB) Federal Contracting Program',
    value: 'WOSB'
  },
  {
    label: 'Service-Disabled Veteran-Owned Small Business (SDVOSB) Program',
    value: 'SDVOSBC'
  },
  {
    label: 'Veteran-Owned Small Business (VOSB) Program',
    value: 'VSA'
  },
  {
    label: 'AbilityOne Program',
    value: 'AP'
  },
  {
    label: 'Disadvantaged Business Enterprise (DBE) Program',
    value: 'DBE'
  },
  {
    label: 'Indian Incentive Program',
    value: 'IIP'
  },
  {
    label: 'Minority Business Enterprise (MBE) Program',
    value: 'MBE'
  },
  {
    label: 'Alaskan Native Corporations (ANCs) and Indian Tribes',
    value: 'ANCs'
  },
  {
    label: 'Native Hawaiian Organizations (NHOs) Program',
    value: 'NHOs'
  }
  // {
  //   label: '8(a) Set-Aside',
  //   value: '8A'
  // },
  // {
  //   label: '8(a) Sole Source',
  //   value: '8AN'
  // },
  // {
  //   label: 'Total Small Business Set-Aside',
  //   value: 'SBA'
  // },
  // {
  //   label: 'Partial Small Business Set-Aside',
  //   value: 'SBP'
  // },
  // {
  //   label: 'Historically Underutilized Business Zone (HUBZone) Set-Aside',
  //   value: 'HZC'
  // },
  // {
  //   label: 'Historically Underutilized Business Zone (HUBZone) Sole Source',
  //   value: 'HZS'
  // },
  // {
  //   label: 'Service-Disabled Veteran-Owned Small Business (SDVOSB) Set-Aside',
  //   value: 'SDVOSBC'
  // },
  // {
  //   label: 'Service-Disabled Veteran-Owned Small Business (SDVOSB) Sole Source',
  //   value: 'SDVOSBS'
  // },
  // {
  //   label: 'Women-Owned Small Business (WOSB) Program Set-Aside',
  //   value: 'WOSB'
  // },
  // {
  //   label: 'Women-Owned Small Business (WOSB) Program Sole Source',
  //   value: 'WOSBSS'
  // },
  // {
  //   label: 'Economically Disadvantaged WOSB (EDWOSB) Program Set-Aside',
  //   value: 'EDWOSB'
  // },
  // {
  //   label: 'Economically Disadvantaged WOSB (EDWOSB) Program Sole Source',
  //   value: 'EDWOSBSS'
  // },
  // {
  //   label: 'Local Area Set-Aside',
  //   value: 'LAS'
  // },
  // {
  //   label: 'Indian Economic Enterprise (IEE) Set-Aside',
  //   value: 'IEE'
  // },
  // {
  //   label: 'Indian Small Business Economic Enterprise (ISBEE) Set-Aside',
  //   value: 'ISBEE'
  // },
  // {
  //   label: 'Buy Indian Set-Aside',
  //   value: 'BICiv'
  // },
  // {
  //   label: 'Veteran-Owned Small Business Set-Aside',
  //   value: 'VSA'
  // },
  // {
  //   label: 'Veteran-Owned Small Business Sole source',
  //   value: 'VSS'
  // },
  // {
  //   label: 'Other Than Small Business',
  //   value: 'Other Than Small Business'
  // }
]

const NAICS_CODES_DATA = [
  {
    label: 'Agriculture, Forestry, Fishing and Hunting',
    value: '11'
  },
  {
    label: 'Mining, Quarrying, and Oil and Gas Extraction',
    value: '21'
  },
  {
    label: 'Utilities',
    value: '22'
  },
  {
    label: 'Construction',
    value: '23'
  },
  {
    label: 'Manufacturing',
    value: '31-33'
  },
  {
    label: 'Wholesale Trade',
    value: '42'
  },
  {
    label: 'Retail Trade',
    value: '44-45'
  },
  {
    label: 'Transportation and Warehousing',
    value: '48-49'
  },
  {
    label: 'Information',
    value: '51'
  },
  {
    label: 'Finance and Insurance',
    value: '52'
  },
  {
    label: 'Real Estate and Rental and Leasing',
    value: '53'
  },
  {
    label: 'Professional, Scientific, and Technical Services',
    value: '54'
  },
  {
    label: 'Management of Companies and Enterprises',
    value: '55'
  },
  {
    label:
      'Administrative and Support and Waste Management and Remediation Services',
    value: '56'
  },
  {
    label: 'Educational Services',
    value: '61'
  },
  {
    label: 'Health Care and Social Assistance',
    value: '62'
  },
  {
    label: 'Arts, Entertainment, and Recreation',
    value: '71'
  },
  {
    label: 'Accommodation and Food Services',
    value: '72'
  },
  {
    label: 'Other Services (except Public Administration)',
    value: '81'
  },
  {
    label: 'Public Administration',
    value: '92'
  }
]

export const DUE_DATES = [
  {
    label: 'This Month',
    value: 'this_month'
  },
  {
    label: 'Next Month',
    value: 'next_month'
  },
  {
    label: 'This Quarter',
    value: 'this_quarter'
  },
  {
    label: 'Next Quarter',
    value: 'next_quarter'
  },
  {
    label: 'This Year',
    value: 'this_year'
  },
  {
    label: 'Next Year',
    value: 'next_year'
  }
]

export {
  BADGE_COLORS,
  NAICS_CODES,
  SET_ASIDES,
  SET_ASIDES_DATA,
  NAICS_CODES_DATA
}
