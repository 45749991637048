import './setupPreference.scss'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { produce } from 'immer'
import { toast } from 'react-toastify'

import LoginContainer from '../../containers/LoginContainer'

import ModifiedMultiSelect from './ModifiedMultiSelectForSetupPreference'
import Modal from '../../components/Modal'
import Loader from '../../components/Loader/Loader'

import { SET_ASIDES_DATA, NAICS_CODES_DATA } from '../../core/data'
import { useAppDispatch, useAppSelector } from '../../hooks/useReduxToolkit'
import { fetchFilterItems, setFetched } from '../../core/store/data/dataSlice'
import { fetchUserDetails, updateDefaultFilters } from '../../core/store/user/userSlice'

import { useModal } from '../../hooks/useModal'
import axiosIct from '../../utils/axios'

import { ReactComponent as RightIcon } from '../../icons/arrow-right-blue.svg'

export default function SetupPreference (): React.ReactElement {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const rfpSources = useAppSelector((s) => s.data.rfpSources)
  const filterItemsFetched = useAppSelector(s => s.data.fetched)
  const defaultFilters = useAppSelector(s => s.user.user?.defaultFilter)
  const placeOfPerformance = useAppSelector(s => s.data.placeOfPerformance)

  const length = 3
  const [modalTitle] = useState('Request new source')
  const [index, setIndex] = useState(0)
  const [save, setSave] = useState(false)
  const [requestSourceName, setRequestName] = useState('')
  const [loading, setLoading] = useState(false)
  // const [contstate, setContstate] = useState(true)

  const {
    isModalOpened: isUserModalOpened,
    closeModal: closeUserModal,
    openModal: openUserModal
  } = useModal()

  const [allFilters, setAllFilters] = useState([{
    subtitle: 'Place Of Performance',
    value: placeOfPerformance.map(nc => nc.value),
    showAll: false,
    options: placeOfPerformance
  },
  {
    subtitle: 'NAICS Code',
    value: defaultFilters.naics_code,
    showAll: false,
    options: NAICS_CODES_DATA.map(nc => ({ ...nc, label: `${nc.value} - ${nc.label}` }))
  },
  {
    subtitle: 'Set-Aside',
    value: defaultFilters.set_aside,
    showAll: false,
    options: SET_ASIDES_DATA
  },
  {
    subtitle: 'RFP Sources',
    value: defaultFilters.rfp_source,
    showAll: false,
    options: rfpSources
  }
  ])

  useEffect(() => {
    if (!filterItemsFetched) {
      setLoading(true)
      dispatch(fetchFilterItems()).finally(() => { setLoading(false) })
    }
  }, [filterItemsFetched])

  // useEffect for dispatching fresh filters detail
  useEffect(() => {
    setAllFilters(() => {
      return produce(allFilters, draft => {
        draft[0].value = placeOfPerformance.map(nc => nc.value)
        draft[1].value = defaultFilters.naics_code
        draft[2].value = defaultFilters.set_aside
        draft[3].value = defaultFilters.rfp_source
      })
    })
  }, [defaultFilters])

  // useEffect for update filter after render
  useEffect(() => {
    const pop = [...placeOfPerformance]
    const index = pop.findIndex((element) => element.label === 'Sam.gov')
    if (index > -1) {
      const sam = pop.splice(index, 1)[0]
      pop.unshift(sam)
    }

    setAllFilters(() => {
      return produce(allFilters, draft => {
        draft[0].options = pop
        draft[3].options = rfpSources
      })
    })
  }, [rfpSources, placeOfPerformance])

  // source request handling
  const handleRequestSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    setLoading(true)
    axiosIct.post('/user/source_request', { request: requestSourceName }).then(() => {
      toast.success('Your Request for source has been submitted!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      })
      setRequestName('')
      closeUserModal()
    }).catch(() => {
      toast.error('Something went wrong!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      })
    }).finally(() => {
      setLoading(false)
    })
    e.preventDefault()
  }

  // useEffect for saving changes
  useEffect(() => {
    if (save) {
      setLoading(true)

      const options = {
        place_of_performance: allFilters[0].value.length > 0 ? allFilters[0].value : allFilters[0].options.map(nc => nc.value),
        naics_code: allFilters[1].value,
        set_aside: allFilters[2].value,
        rfp_source: allFilters[3].value
      }

      void dispatch(updateDefaultFilters(options)).then(() => {
      }).then(async () => await dispatch(fetchUserDetails())).then(() => dispatch(setFetched(false))).then(() => { navigate('/') }).then(() => { setLoading(false) })
    }
    setSave(false)
  }, [save])

  const onFilterChange = (index: number, values: any): void => {
    setAllFilters(() => {
      return produce(allFilters, draft => {
        draft[index].value = values
      })
    })
  }

  const onNaicsChange = (values: any): void => {
    onFilterChange(1, values)
    // if (values.length === 0) {
    //   console.log(values)
    //   setContstate(true)
    // } else {
    //   setContstate(false)
    //   console.log(values)
    // }
  }

  return (
    <>
      {loading && <Loader />}
      <LoginContainer>

        <div className='Setup Setup__card Login__card px-4 px-lg-3 d-flex flex-column align-items-center justify-content-center w-100'>
          <div className='Setup__title fw-bold mb-2'>Set up your preferences so we know how to rank the best RFPs for you. You can always search and view all RFPs.</div>

          <div className='Setup__navigate'>
            <a className={index === 0 ? 'Setup__active' : 'Setup__inactive'} onClick={() => { setIndex(0) }}> NAICS Code </a> <RightIcon />
            <a className={index === 1 ? 'Setup__active' : 'Setup__inactive'} onClick={() => { setIndex(1) }}> Place of Performance </a> <RightIcon />
            <a className={index === 2 ? 'Setup__active' : 'Setup__inactive'} onClick={() => { setIndex(2) }}> Set Aside </a>
          </div>

          <div className='Setup__line' />

          {index === 0 && (
            <div className="rfp-settings-edit-info-category">
              <ModifiedMultiSelect
                titleSubHeading='Tell us the work your’re interested in. Feel free to be as specific or general as you’d like. Please Enter atleast 2 digit for NAICS code. To enter a new NAICS code, press ’Enter’'
                value={allFilters[1].value}
                currentIndex={index + 1}
                isSelectAllOption={true}
                clearAllSelectOption={true}
                length={length}
                title={allFilters[1].subtitle}
                key={allFilters[1].subtitle}
                options={allFilters[1].options}
                onChange={(values) => { onNaicsChange(values) }}
              />
            </div>
          )
          }
          {index === 1 &&
            (<div className="rfp-settings-edit-info-category">
              <ModifiedMultiSelect
                titleSubHeading='When you select a state, we will include any municipalities in that state where we are currently collecting data.'
                value={allFilters[0].value}
                currentIndex={index + 1}
                isSelectAllOption={true}
                clearAllSelectOption={true}
                length={length}
                title={allFilters[0].subtitle}
                key={allFilters[0].subtitle}
                options={allFilters[0].options}
                onChange={(values) => { onFilterChange(0, values) }}
              />
            </div>
            )
          }
          {
            index === 2 &&
            (<div className="rfp-settings-edit-info-category">
              <ModifiedMultiSelect
                titleSubHeading='We’ll include any set-asides you qualify for.'
                selectAtLeastOne={true}
                value={allFilters[2].value}
                isSelectAllOption={true}
                key={allFilters[2].subtitle}
                openUserModal={openUserModal}
                currentIndex={index + 1}
                clearAllSelectOption={true}
                length={length}
                title={allFilters[2].subtitle}
                options={allFilters[2].options}
                onChange={(values) => { onFilterChange(2, values) }}
                requestSource={true}
              />
            </div>
            )
          }
          <div className="button-container">
            {
              index >= 0 &&
              <button onClick={() => { if (index > 0) setIndex(index - 1) }} className="btn btn-lg mx-2  back-button">
                <span>Back</span>
              </button>
            }
            {index < length - 1
              ? <button onClick={() => { if (index < length - 1) setIndex(index + 1) }} className='btn btn-lg continue-button ' >
                Continue
              </button>
              : <button onClick={() => { if (index < length - 1) setIndex(index + 1); setSave(true) }} className='btn btn-lg continue-button ' >
                Finish
              </button>}
              {
              <button onClick={() => { setSave(true) }} className="btn btn-lg skip-button">
                <span>Skip for Now</span>
              </button>
            }
          </div>
          {/* {index === 2 && <span className='Preference-skip-text' onClick={() => { setIndex(index + 1) }} style={{ cursor: 'pointer' }} >Skip for now</span>} */}
        </div>
      </LoginContainer>

      <Modal isOpened={isUserModalOpened} closeModal={closeUserModal} title={modalTitle} >
        <h2 className="modal__applied__title">Request new source</h2>
        <div className="modal__applied__text">
          Letting us know will mark this RFP as “Applied” and <br />
          improve our ability to find your Right-Fit RFP.
        </div>
        <form onSubmit={handleRequestSubmit} style={{ width: '90%', margin: 'auto' }} >
          <p className="fw-bold mb-2">Source URL</p>
          <input onChange={(e) => { setRequestName(e.target.value) }} value={requestSourceName} className='w-100 p-3 sourceInput'/>
          <div className="modal__applied__buttons  ">
            <button type="submit" className="btn request-button ">Send Request</button>
          </div>
        </form>
      </Modal>
    </>
  )
}
