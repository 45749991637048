import React from 'react'
import { Navigate, Route, Routes as Switch } from 'react-router-dom'
import { type IRoute } from './core/types'

interface Props {
  routes: IRoute[]
}

export default function Routes ({ routes }: Props): React.ReactElement {
  return (
    <Switch>
      {routes.map((route) => {
        if (route.redirectTo != null) {
          return (
            <Route
              path={route.path}
              key={`redirect-${route.path}`}
              element={<Navigate to={route.redirectTo} />}
            />
          )
        }
        return <Route key={route.path} {...route} />
      })}
    </Switch>
  )
}
