import './other.scss'
import React from 'react'

import Card from '../../components/Card/Card'

export default function TermsConditions (): React.ReactElement {
  return (
    <div className='container py-4 py-lg-5'>
      <Card title="One Click RFP Terms of Service">
        Last Updated: June 2, 2023 <br />
        <div className="privacyContainer" >
          <ol className="m-0">
            <li>Acceptance of Terms</li>
            <div className="ml--5" >
              These Terms of Service ("Terms") govern your access to and use of the One Click RFP software, services, and website (collectively, the "Service") provided by Abundat LLC, doing business as Teammate AI ("Teammate AI," "we," "us," "our").
            </div>

            <li>Access to the Service</li>
            <div>
              Subject to these Terms, we grant you a limited, non-exclusive, non-transferable, and revocable license to use the Service. You must be at least the age of majority in your country or state to use the Service.
            </div>
            <li>User Accounts</li>
            <div>
              You must provide accurate and complete information when creating an account. You are responsible for all activities that occur under your account and you agree to maintain the security of your account username and password.
            </div>
            <li>User Conduct</li>
            <div>
              You agree not to misuse the Service. For example, you must not:<br></br>
              a) Copy, modify, distribute, sell, or lease any part of the Service.<br></br>
              b) Use the Service in any illegal manner or to violate any laws.<br></br>
              c) Attempt to gain unauthorized access to the Service or related systems or networks.<br></br>
              d) Scrape, data mine, or use any other automated means to extract data or content from the Service.<br></br>
            </div>
            <li>Intellectual Property</li>
            <div>
              All rights, title, and interest in and to the Service, including all associated intellectual property rights, are and will remain the property of Teammate AI.
            </div>
            <li>Termination</li>
            <div>
              We may suspend or terminate your access to the Service at any time, with or without cause, and with or without notice. Upon termination, you continue to be bound by Sections 4 and 5.
            </div>
            <li>Disclaimer of Warranties</li>
            <div>
              The Service is provided "as is" and on an "as available" basis, without warranty of any kind, express or implied.
            </div>
            <li>Limitation of Liability</li>
            <div>
              To the maximum extent permitted by law, Teammate AI shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues.
            </div>
            <li>Changes to the Terms</li>
            <div>
              We reserve the right to modify these Terms at any time. If we make changes that are material, we will provide you with notice before such changes take effect.
            </div>
            <li>Governing Law</li>
            <div>
              These Terms are governed by the laws of the State of Delaware, without regard to conflict of law principles.
              <ol className="m-0">
                <li>Contact Information</li>
              </ol>
            </div>
          </ol>
        </div>
        If you have any questions about these Terms, please contact us at:{' '}
        <a href="mailto:support@teammateai.com">support@teammateai.com</a> <br />
        By using the Service, you confirm that you accept these Terms and that you agree to comply with them. If you do not agree to these Terms, you must not use the Service. <br />
        Teammate AI, dba Abundat LLC
      </Card>
    </div>
  )
}
