import { useState } from 'react'
import { lockScroll } from '../core/helpers'

interface ModalReturnType {
  isModalOpened: boolean
  closeModal: () => void
  openModal: () => void
}

export const useModal = (initialOpened = false): ModalReturnType => {
  const [isModalOpened, setIsModalOpened] = useState(initialOpened)

  const closeModal = (): void => {
    setIsModalOpened(false)
    lockScroll(false)
  }

  const openModal = (): void => {
    setIsModalOpened(true)
    lockScroll(true)
  }

  return {
    isModalOpened,
    closeModal,
    openModal
  }
}
