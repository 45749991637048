import mixpanel from 'mixpanel-browser'

if (typeof process.env.REACT_APP_MIX_PANEL === 'string' && process.env.REACT_APP_MIX_PANEL.length !== 0) {
  mixpanel.init(process.env.REACT_APP_MIX_PANEL)
}

export default {
  track: (event: string) => {
    if (typeof process.env.REACT_APP_MIX_PANEL === 'string' && process.env.REACT_APP_MIX_PANEL.length !== 0) {
      mixpanel.track(event)
    }
  }
}
