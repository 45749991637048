import './Input.scss'
import classNames from 'classnames'
import React, { type ChangeEventHandler, type FocusEventHandler } from 'react'

interface Props {
  value: string
  type?: 'text' | 'password'
  onChange: ChangeEventHandler
  name: string
  label?: string
  className?: string
  required?: boolean
  readOnly?: boolean
  disabled?: boolean
  placeholder?: string
  error?: boolean | string | undefined
  onBlur?: FocusEventHandler
  style?: object
}

export default function Input ({
  value,
  type = 'text',
  onChange,
  onBlur,
  name,
  label,
  className = ' ',
  readOnly = false,
  disabled = false,
  placeholder = '',
  error,
  style = {}
}: Props): React.ReactElement {
  return (
    <div className={`Input ${className}`} style={style}>
      {!(label == null) && <div className="Input__label fw-bold mb-2">{label}</div>}
      <div className="d-flex">
        <div className="flex-grow-1">
          <input
            value={value}
            onChange={onChange}
            type={type}
            className={classNames('Input__input w-100', {
              'Input__input--error': error
            })}
            name={name}
            disabled={disabled}
            onBlur={onBlur}
            readOnly={readOnly}
            placeholder={placeholder}
          />
        </div>
      </div>
      {error !== undefined && <div className="Input__error text-end">{error}</div>}
    </div>
  )
}
