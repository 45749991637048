import React, { type PropsWithChildren } from 'react'
// import { ReactComponent as Logo2Icon } from '../icons/logo2.svg'

export default function LoginContainer ({ children }: PropsWithChildren): React.ReactElement {
  return (
    <div className="anim-right d-flex flex-column justify-content-center w-100 h-100 text-align-center">
      <div className='flex-column w-100 h-100 justify-content-center align-items-center'>
        {/* <Logo2Icon /> */}
        {children}
      </div>
    </div>
  )
}
