import './Settings.scss'
import React from 'react'
import AccountSetting from './AccountSetting'
// import UsersSetting from './UsersSetting'
import BillingSetting from './BillingSetting'
import RfpSettings from './preferences'
import NotificationSetting from './Notification'

import { useAppDispatch, useAppSelector } from '../../hooks/useReduxToolkit'
import { setSettingTab } from '../../core/store/setting/settingslice'

import { ReactComponent as AccountIcon } from '../../icons/account.svg'
import { ReactComponent as DollarIcon } from '../../icons/dollar.svg'
import { ReactComponent as SettingIcon } from '../../icons/settings.svg'
import { ReactComponent as NotificationIcon } from '../../icons/notification-bell.svg'

export default function Settings (): React.ReactElement {
  const dispatch = useAppDispatch()
  const tab = useAppSelector((s) => s.settings.settingTab)

  const billingUrl = process.env.REACT_APP_STRIPE_BILLING_URL

  const Menu = [
    {
      label: 'Account',
      icon: <AccountIcon />,
      component: <AccountSetting />
    },
    {
      label: 'Billing',
      icon: <DollarIcon />,
      component: <BillingSetting />
    },
    // {
    //   label: 'Users',
    //   icon: <AccountIcon />,
    //   component: <UsersSetting />
    // },
    {
      label: 'RFP Settings',
      icon: <SettingIcon />,
      component: <RfpSettings />
    },
    {
      label: 'Notifications',
      icon: <NotificationIcon />,
      component: <NotificationSetting />
    }
  ]

  return (
    <div className="container-settings">
      <div className='container settings py-5'>
        <div className="setting-menu">
          <h5>Settings</h5>
          <ul>
            {
              Menu.map((item, i) =>
                <li
                  key={item.label + String(i)}
                  className={i === tab ? 'active' : ''}
                  onClick={() => { i === 1 ? window.open(billingUrl, '_blank') : dispatch(setSettingTab(i)) }}
                >
                  {item.icon}{item.label}
                </li>
              )
            }
          </ul>
        </div>
        <div className="setting-content">
          <div style={{ marginBottom: 50 }}>
            <h5 className="subtitle">Settings</h5>
            <h3 className="title">{Menu[tab].label}</h3>
          </div>
          {
            Menu[tab].component
          }
        </div>
        {/* <div className="setting-right"></div> */}
      </div>
    </div>
  )
}
