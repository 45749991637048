import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { RoutesEnum } from '../../enums'
import axiosIct from '../../../utils/axios'

export interface User {
  name: string
  lastname: string
  email: string
  company: string
  companyId: string
  userId: string
  isAdmin: boolean
  isOnBoarding?: boolean
  placeOfPerformance: Array<{ label: string, value: string, isDefault: boolean }>
  defaultFilter: {
    naics_code: string[]
    place_of_performance: string[]
    rfp_source: string[]
    right_fit_rfp_score: any[]
    set_aside: string[]
  }
  subscriptionStatus: string
  trialDaysLeft?: number
  sendDailyUpdate: boolean
  preferenceSetupComplete: boolean
  emailRightFitRfpScores: string[]
}

interface UserState {
  user: User
  changed: boolean
}

const storedUser = localStorage.getItem('user')

const initialState: UserState = {
  user: storedUser != null
    ? JSON.parse(storedUser)
    : {
        name: '',
        lastname: '',
        email: '',
        company: '',
        companyId: '',
        userId: '',
        isAdmin: false,
        isOnBoarding: false,
        placeOfPerformance: [],
        defaultFilter: {
          naics_code: [],
          place_of_performance: [],
          rfp_source: [],
          right_fit_rfp_score: [],
          set_aside: []
        },
        subscription_status: 'notvalid',
        trial_days_left: 0,
        send_daily_update: true,
        email_right_fit_rfp_scores: ['A']
      },
  changed: true
}

interface LoginPayload {
  email: string | null
  password: string | null
}

interface MagicLinkPayload {
  email: string | null
  session: string | null
  token: string | null
}

export interface SignupPayload {
  name: string
  lastname: string
  email: string
  company: string
  password: string
}

export const loginUser = createAsyncThunk(
  'user/loginUser',
  async (payload: LoginPayload) => {
    const { email = '', password = '' } = payload
    return await axiosIct
      .post('user/login', {
        email,
        password
      })
      .then((response) => response.data)
  }
)

export const loginUserMagicLink = createAsyncThunk(
  'user/verify-magic-link',
  async (payload: MagicLinkPayload) => {
    const { email = '', token = '', session = '' } = payload
    return await axiosIct
      .post('user/verify-magic-link', {
        email,
        session,
        token
      })
      .then((response) => response.data)
  }
)

export const fetchUserDetails = createAsyncThunk('user/fetchUser', async () => {
  return await axiosIct.get('/user/me').then((r) => r.data)
})

export const updateUserDetail = createAsyncThunk(
  'user/updateDetail',
  async (options: any, { dispatch }) => {
    const name = options.name
    const lastname = options.lastname
    const company = options.company
    const password = options.password
    const sendDailyUpdate = options?.send_daily_update
    const emailRightFitRfpScores = options?.email_right_fit_rfp_scores
    return await axiosIct
      .put('/user/me', { name, lastname, company, password, send_daily_update: sendDailyUpdate, email_right_fit_rfp_scores: emailRightFitRfpScores })
      .then((r) => r.data)
      .then(() => {
        void dispatch(fetchUserDetails())
      })
  }
)

export const updateDefaultFilters = createAsyncThunk(
  'user/updateDefaultFilters',
  async (options: any) => {
    return await axiosIct.put('/user/default_filter', options).then((r) => r.data)
  }
)

export const deleteAccount = createAsyncThunk(
  'user/deleteAccount',
  async () => {
    return await axiosIct.delete('/user/me')
  }
)

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (
      state,
      action: {
        payload: User
      }
    ) => {
      state.user = action.payload
    },
    logOutUser: () => {
      localStorage.removeItem('user')
      localStorage.removeItem('id_token')
    },
    setChanged: (state, action) => {
      state.changed = action.payload
    }
  },
  extraReducers: (builder) => {
    // buildercase for login

    builder.addCase(loginUser.fulfilled, (state, action) => {
      if (action.payload?.challenge === 'NEW_PASSWORD_REQUIRED') {
        localStorage.setItem('session', action.payload.session)
        localStorage.setItem('email', action.payload.email)
        window.location.href = '/reset-password'
        return
      }

      if (action.payload?.notconfirmed === true) {
        localStorage.setItem('email', action.payload.email)
        localStorage.setItem('password', action.payload.password)
        window.location.href = '/confirm-account'
      }

      handleUserLoginFulfilled(state, action)
      if (!state.user.preferenceSetupComplete) {
        window.location.href = RoutesEnum.SetupPreference
      }

      if (localStorage.getItem('showBilling') === 'true') {
        localStorage.removeItem('showBilling')
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        window.location.href = process.env.REACT_APP_STRIPE_URL !== undefined ? `${process.env.REACT_APP_STRIPE_URL}?prefilled_email=${state.user.email}` : RoutesEnum.Settings
      }
    })

    builder.addCase(loginUser.rejected, (state, action) => {
      toast.error('Incorrect Username or Password', {
        position: 'top-right',
        autoClose: 4500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: 'dark'
      })
    })

    builder.addCase(loginUserMagicLink.fulfilled, (state, action) => {
      if (action.payload?.session_expired === true) {
        window.location.href = RoutesEnum.MagicLinkSessionExpired
      }
      handleUserLoginFulfilled(state, action)
      if (!state.user.preferenceSetupComplete) {
        window.location.href = RoutesEnum.SetupPreference
      }
    })

    builder.addCase(loginUserMagicLink.rejected, () => {
      toast.error('Failed to log in with magic link. Please try again later.', {
        position: 'top-right',
        autoClose: 4500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: 'dark'
      })
    })
    builder.addCase(fetchUserDetails.fulfilled, (state, action) => {
      const {
        name,
        lastname,
        email,
        company,
        is_admin: isAdmin,
        user_id: userId,
        default_filter: defaultFilter,
        place_of_performance: placeOfPerformance,
        companyId,
        subscription_status: subscriptionStatus,
        trial_days_left: trialDaysLeft,
        send_daily_update: sendDailyUpdate,
        email_right_fit_rfp_scores: emailRightFitRfpScores,
        preference_setup_complete: preferenceSetupComplete
      } = action.payload
      const fetchedUser: User = {
        email,
        name,
        lastname,
        company,
        companyId,
        userId,
        isAdmin,
        isOnBoarding: true,
        defaultFilter,
        placeOfPerformance,
        subscriptionStatus,
        trialDaysLeft,
        sendDailyUpdate,
        preferenceSetupComplete,
        emailRightFitRfpScores
      }
      state.user = fetchedUser
      localStorage.setItem('user', JSON.stringify(fetchedUser))
      state.changed = true
    })
  }
})

function handleUserLoginFulfilled (state: any, action: any): void {
  const {
    name,
    lastname,
    email,
    company,
    is_admin: isAdmin,
    user_id: userId,
    default_filter: defaultFilter,
    place_of_performance: placeOfPerformance,
    company_id: companyId,
    subscription_status: subscriptionStatus,
    trial_days_left: trialDaysLeft,
    send_daily_update: sendDailyUpdate,
    preference_setup_complete: preferenceSetupComplete,
    email_right_fit_rfp_scores: emailRightFitRfpScores
  } = action.payload.user

  const fetchedUser: User = {
    email,
    lastname,
    name,
    company,
    companyId,
    userId,
    isAdmin,
    isOnBoarding: true,
    defaultFilter,
    placeOfPerformance,
    subscriptionStatus,
    trialDaysLeft,
    sendDailyUpdate,
    preferenceSetupComplete,
    emailRightFitRfpScores
  }

  state.user = fetchedUser
  localStorage.setItem('user', JSON.stringify(fetchedUser))
  localStorage.setItem('id_token', action.payload.id_token)

  state.changed = true
}

export const { setUser, logOutUser, setChanged } = userSlice.actions
export default userSlice.reducer
